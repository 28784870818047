import { Config } from './config.d'

const config: Config = {
    GRAPHQL_HOST: 'https://contember.icedev.cz/content/mccann-square/live',
    GRAPHQL_TOKEN: '86c9f5d958cc7f15dfd3dcd967c09ff5513f782a',
    ADMIN_URL: 'https://xadm-mccann-square.icedev.cz/mccann-square/',
    PICFIT_DISPLAY_URL: 'https://picfit.icedev.cz/display',
};

export default config
