export default [
    {
        number: 1,
        name: "Harmful",
        desc: "An ad so bad it would make you publicly riot against the brand.",
        color: "#ca1922"
    },
    {
        number: 2,
        name: "Poor",
        desc: "You actively hated every second of having to watch this ad.",
        color: "#cb2357"
    },
    {
        number: 3,
        name: "Boring",
        desc: "An ad that makes you want to skip it twice, just to be sure.",
        color: "#cb267b"
    },
    {
        number: 4,
        name: "Irrelevant",
        desc: "The ad is not particularly anything. It just exists.",
        color: "#cc26c4"
    },
    {
        number: 5,
        name: "Average",
        desc: "You have seen many like this ad, and many more will follow.",
        color: "#ba28d0"
    },
    {
        number: 6,
        name: "Fun",
        desc: "You were intrigued watching this ad, and that’s something.",
        color: "#6f25d0"
    },
    {
        number: 7,
        name: "Exciting",
        desc: "The ad made you feel feelings and want to do actions.",
        color: "#5e27d0"
    },
    {
        number: 8,
        name: "Shareable",
        desc: "You would unironically share this ad on your personal wall.",
        color: "#2c25d0"
    },
    {
        number: 9,
        name: "Transformative",
        desc: "An ad you will remember, because it changed something in you.",
        color: "#203ad0"
    },
    {
        number: 10,
        name: "Legendary",
        desc: "One that goes right up there with “1984” or that fat kid running.",
        color: "#1b74cd"
    }
];
