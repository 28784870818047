<template>
    <span class="score">
        <span :class="{'large': large}">
            <strong>{{formatScore(score)}}</strong>
        </span>
        <template
            v-if="scoreNaming">
            <span class="score-name">{{getName(score)}}</span>
        </template>
    </span>
</template>

<script>
// @ is an alias to /src
// import ComponentName from '@/components/ComponentName.vue';

export default {
    name: 'ScoreBadgeComponent',
    components: {
    },
    props: {
        data: {
            score: Number
        },
        large: {
            type: String,
            required: false,
            default: ''
        },
        scoreNaming: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {

        const getName = function(score) {
            if (score === 1) {
                return 'HARMFUL';
            } else if (score > 1 && score <= 2) {
                return 'ANNOYING';
            } else if (score > 2 && score <= 3) {
                return 'APATHETIC';
            } else if (score > 3 && score <= 4) {
                return 'AVERAGE';
            } else if (score > 4 && score <= 5) {
                return 'COOL';
            } else if (score > 5 && score <= 6) {
                return 'GOOD';
            } else if (score > 6 && score <= 7) {
                return 'BOOM';
            } else if (score > 7 && score <= 8) {
                return 'STAR';
            } else if (score > 8 && score <= 9) {
                return 'HEROIC';
            } else if (score > 9) {
                return 'HISTORIC';
            } else {
                return '';
            }
        }

        const formatScore = function (score) {
            return score?.toFixed(1)
        }

        return {
            score: props.data,
            getName: getName,
            formatScore: formatScore,
        }
    }
};
</script>
