
import { defineComponent, defineAsyncComponent } from 'vue'
import { ContentBlock } from '@/types/graphql.sdk'
import Container from "@/components/Container.vue";

export default defineComponent({
    components: {
        Container,
        contentSectionBlock: defineAsyncComponent(() => import('./ContentSection.vue')),
        quotationBlock: defineAsyncComponent(() => import('./Quotation.vue')),
        quotationWithImageBlock: defineAsyncComponent(() => import('./QuotationWithImage.vue')),
        imageWithTextBlock: defineAsyncComponent(() => import('./ImageWithText.vue')),
        videoWithTextBlock: defineAsyncComponent(() => import('./VideoWithText.vue'))
    },
    props: {
        blocks: {
            type: Array as () => ContentBlock[],
            required: true
        }
    },
    setup(props) {
        return {
            blockList: props.blocks
        }
    }
})
