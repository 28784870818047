import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Story from "../views/Story.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/story/:id",
        name: "Story",
        component: Story
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
