import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54a98504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "story__visual" }
const _hoisted_2 = { class: "story__description" }
const _hoisted_3 = { class: "story__clientname" }
const _hoisted_4 = { class: "story__projectname" }
const _hoisted_5 = { class: "story__tags" }
const _hoisted_6 = { class: "story__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PicfittedImage = _resolveComponent("PicfittedImage")!
  const _component_ScoreBadgeComponent = _resolveComponent("ScoreBadgeComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, {
      to: `/story/${_ctx.data.id}`,
      class: "story"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.data.thumbnail)
            ? (_openBlock(), _createBlock(_component_PicfittedImage, {
                key: 0,
                src: _ctx.data.thumbnail?.url,
                alt: _ctx.data.thumbnail?.localesByLocale?.alt,
                "no-lightbox": "",
                height: "250",
                class: "story__image"
              }, null, 8, ["src", "alt"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ScoreBadgeComponent, {
            data: _ctx.data.score?.average
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.data.client?.label), 1),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.data.projectName), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatDate(_ctx.data.date)), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}