import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "square-content--admin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container = _resolveComponent("container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockList, (block) => {
      return (_openBlock(), _createElementBlock("div", {
        key: block.id,
        class: "square-content"
      }, [
        _createVNode(_component_container, {
          variant: block?.bgColor,
          content: "narrow",
          class: "bg-white"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(block.type + 'Block'), _normalizeProps(_guardReactiveProps(block)), null, 16))
          ]),
          _: 2
        }, 1032, ["variant"])
      ]))
    }), 128))
  ]))
}