
import StoryItem from '@/components/Story.vue';
import {getSdk} from '@/types/graphql.sdk'
import type {Story} from '@/types/graphql.sdk'
import graphqlClient from '@/core/graphql';
import config from '@/config/config'
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Home',
    components: {
        StoryItem
    },
    data() {
        return {
            rawStories: [] as Story[],
            toShow: 6,
            sortType: 'score' as 'score' | 'name',
            adminUrl: config.ADMIN_URL,
        }
    },
    computed: {
        stories: function (): Story[] {
            return this.rawStories.slice(0, this.toShow)
        }
    },
    methods: {
        getStories: async function () {
            const response = await getSdk(graphqlClient).stories()

            this.rawStories = response.stories as Story[]
            this.filterHandler({target: {value: 'score'}})
        },
        filterHandler: function (event: any) {
            const type = event.target.value

            this.rawStories.sort((a, b) => {
                const aValue = type === 'score' ? a.score?.average ?? 0 : a.projectName.toLowerCase()
                const bValue = type === 'score' ? b.score?.average ?? 0 : b.projectName.toLowerCase()
                if (aValue === bValue) {
                    return 0
                }

                return aValue > bValue ? -1 : 1
            })
        }
    },
    mounted() {
        this.getStories()
    },
});
