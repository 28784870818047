
import scoredata from '@/store/scoredata';
import {Ref, ref} from "vue";
import {useRoute} from 'vue-router';
import ScoreBadgeComponent from '@/components/ScoreBadge.vue';
import {getSdk} from '@/types/graphql.sdk'
import BlockContainer from '@/components/blocks/BlockContainer.vue';
import '@splidejs/splide/dist/css/splide.min.css';
import graphqlClient from '@/core/graphql';
import StoryRating from "@/components/StoryRating.vue";
import {StoryWithPartialScore} from "@/types/Story";
import config from "@/config/config";
import PicfittedImage from "@/components/PicfittedImage.vue";
import {useMainStore} from "@/store/mainStore";

export default {
    name: "Story",
    components: {
        PicfittedImage,
        StoryRating,
        BlockContainer,
        ScoreBadgeComponent,
    },
    setup() {
        const scoreData = scoredata;
        const route = useRoute();
        const id = route.params.id as string
        const story: Ref<StoryWithPartialScore | null> = ref(null);
        const loading: Ref<boolean> = ref(true);

        const splideOptions = {
            type: 'loop',
            direction: 'ttb',
            height: '15rem',
            focus: 'center',
            width: '100%',
            autoHeight: true,
            gap: 20,
            start: 5,
            pagination: false,
            flickPower: 50,
            flickMaxPages: 1,
            perPage: 1,
            easing: 'cubic-bezier(0, 0, 1, 1)',
            speed: 100
        }
        const showDescription: Ref<boolean> = ref(false);
        const showStripe: Ref<boolean> = ref(false);
        const ratingButton: Ref<HTMLButtonElement | null> = ref(null)
        const ratingStripe: Ref<HTMLElement | null> = ref(null)
        const initialPosition = window.scrollY;
        const adminUrl = config.ADMIN_URL;
        const canVoteForStory: Ref<boolean> = ref(false);

        const getStory = async (storyId: string) => {
            loading.value = true
            const response = await getSdk(graphqlClient).storyById({id: storyId})

            // @ts-ignore
            story.value = response.story

            canVoteForStory.value = useMainStore().canVoteForStory(storyId)
            loading.value = false
        };

        getStory(id)

        window.onscroll = function () {
            if (ratingButton.value === null) return

            const rect = ratingButton.value.getBoundingClientRect();
            const buttonBottomPosition = rect.bottom;

            if (buttonBottomPosition <= initialPosition) {
                showStripe.value = true
            } else {
                showStripe.value = false
            }
        }


        const openFullScreen: Ref<boolean> = ref(false)

        const videoCaseStudy: Ref<HTMLVideoElement | null> = ref(null)
        const videoPlaying: Ref<Boolean> = ref(false)

        const playVideo = function () {
            if (videoCaseStudy.value === null)
                return;

            if (!videoPlaying.value) {
                videoCaseStudy.value.play()
                videoCaseStudy.value.setAttribute('controls', '');
                videoPlaying.value = true
            }
        };

        let rateButton = ref(false);
        let showRating = ref(false);
        let detailSource: Ref<{ source: string | null, type: string | null }> = ref({
            source: null,
            type: null
        });
        let showModal = ref(false);

        const onShowModal = function (source: string, type: string) {
            detailSource.value = {
                source: source,
                type: type
            };
            showModal.value = true;
        }

        if (route.query.rate) {
            rateButton.value = true;
        }

        let voteEmail: Ref<string | null> = ref(null);
        let voteEmailError: Ref<string | null> = ref(null);

        const hideRatingAndRefresh = () => {
            showRating.value = false
            document.body.style.overflow = 'visible'
            getStory(id)
        }

        const handleModal = () => {
            showRating.value = true
            document.body.style.overflow = 'hidden'
        }

        return {
            story,
            showRating,
            scoreData,
            rateButton,
            detailSource,
            splideOptions,
            onShowModal,
            showModal,
            voteEmail,
            voteEmailError,
            playVideo,
            openFullScreen,
            videoCaseStudy,
            showDescription,
            videoPlaying,
            showStripe,
            ratingButton,
            ratingStripe,
            hideRatingAndRefresh,
            handleModal,
            loading,
            adminUrl,
            canVoteForStory,
        };
    },
    unmounted() {
        document.body.style.overflow = 'visible'
    }
};
