
import ScoreBadgeComponent from '@/components/ScoreBadge.vue';
import {Story} from '@/types/graphql.sdk'
import {defineComponent, PropType} from 'vue'
import PicfittedImage from "@/components/PicfittedImage.vue";

export default defineComponent({
    name: 'StoryComponent',
    components: {
        PicfittedImage,
        ScoreBadgeComponent
    },
    props: {
        data: {
            type: Object as PropType<Story>,
            required: true
        },
        index: Number
    },
    setup(props) {
        return {
            ...props
        }
    },

    methods: {
        formatDate: (dateToFormat: string | null) => {
            if (typeof dateToFormat !== 'string') {
                return ''
            }

            const date = new Date(dateToFormat)
            return new Intl.DateTimeFormat('en-US', {month: 'short', year: '2-digit'}).format(date)
        }
    },
});
