

import {computed, reactive, ref, Ref} from "vue";
import graphqlClient from "@/core/graphql";
import {useMainStore} from "@/store/mainStore";
import type {Story} from '@/types/graphql.sdk'
import {getSdk, VoteCreateInput, VoteTypeEnum} from '@/types/graphql.sdk'

export default {
    name: "StoryRating",
    components: {},
    emits: ['close'],
    props: {
        story: Object as () => Story
    },
    setup(props: any & { story: Story }) {
        const success: Ref<boolean> = ref(false);
        let step: Ref<number> = ref(0)

        const voteError: Ref<boolean> = ref(false);
        const voteSuccess: Ref<boolean> = ref(false);
        const voteProcessing: Ref<boolean> = ref(false);

        // eslint-disable-next-line no-unused-vars
        const votes: { [key in VoteTypeEnum]?: number } = reactive({})
        const votedTopics: VoteTypeEnum[] = []

        const topics: VoteTypeEnum[] = [
            VoteTypeEnum.Storytelling,
            VoteTypeEnum.Craft,
            VoteTypeEnum.Distinctiveness,
            VoteTypeEnum.Salience,
            VoteTypeEnum.Targeting,
            VoteTypeEnum.Insight,
        ]

        interface RatingItem {
            id: number
            classNumber: string,
            headline: string,
            text: string
        }

        const ratingItems: RatingItem[] = [
            {
                id: 1,
                classNumber: 'one',
                headline: 'Harmful',
                text: 'An ad so bad it would make you publicly riot against the brand.'
            },
            {
                id: 2,
                classNumber: 'two',
                headline: 'Poor',
                text: 'You actively hated every second of having to watch this ad.'
            },
            {
                id: 3,
                classNumber: 'three',
                headline: 'Boring',
                text: 'An ad that makes you want to skip it twice, just to be sure.'
            },
            {
                id: 4,
                classNumber: 'four',
                headline: 'Irrelevant',
                text: 'The ad is not particularly anything. It just exists.'
            },
            {
                id: 5,
                classNumber: 'five',
                headline: 'Average',
                text: 'You have seen many like this ad, and many more will follow.'
            },
            {
                id: 6,
                classNumber: 'six',
                headline: 'Fun',
                text: 'You were intrigued watching this ad, and that’s something.'
            },
            {
                id: 7,
                classNumber: 'seven',
                headline: 'Exciting',
                text: 'The ad made you feel feelings and want to do actions.'
            },
            {
                id: 8,
                classNumber: 'eight',
                headline: 'Shareable',
                text: 'You would unironically share this ad on your personal wall.'
            },
            {
                id: 9,
                classNumber: 'nine',
                headline: 'Transformative',
                text: 'An ad you will remember, because it changed something in you.'
            },
            {
                id: 10,
                classNumber: 'ten',
                headline: 'Legendary',
                text: 'One that goes right up there with “1984” or that fat kid running.'
            },
        ]

        const hoveredScore = ref(0);

        // const ratingButton: Ref<HTMLButtonElement | null> = ref(null)
        const ratingStripe: Ref<HTMLElement | null> = ref(null)
        const moveByValue: Ref<number> = ref(0)
        const animateElement: Ref<boolean> = ref(false);
        let multiClickPrevention = false;

        const mobileVoted: Ref<number> = ref(0)
        const mobileScrollbarItem: Ref<HTMLElement[]> = ref([])

        const delayedExec = (timeoutMs: number, afterFn: any) => {
            var timer: number;
            return () => {
                timer && clearTimeout(timer);
                // @ts-ignore
                timer = setTimeout(afterFn, timeoutMs);
            };
        };

        const handleScroll = delayedExec(2, () => {
            const middle = window.innerWidth / 2;

            const elementIndex = mobileScrollbarItem.value.findIndex((element: HTMLElement) => {
                const box = element.getBoundingClientRect()
                const pos = middle - (box.x + box.width)
                return pos < 0
            })

            if (elementIndex > -1) {
                mobileVoted.value = parseInt(mobileScrollbarItem.value[elementIndex]?.dataset?.id ?? '0')
                storeVote(topics[step.value], mobileVoted.value, null)
            }
        })

        const selectByTap = (_: Event, id: number) => {
            if (id !== 1 || mobileVoted.value > 0) {
                return
            }
            mobileVoted.value = id
            storeVote(topics[step.value], id, null)
        }

        const storeVote = (type: VoteTypeEnum, score: number, ratingButton: HTMLElement|null) => {
            votes[type] = score
            if (topics.indexOf(type) === (topics.length - 1)) {
                votedTopics.push(type)
            }

            if (ratingButton) {
                ratingButton.classList?.add('flex')

                moveByValue.value = 0

                const rect = ratingButton.getBoundingClientRect()

                const previousMove = moveByValue.value
                moveByValue.value = rect.left - ((window.innerWidth / 2) + (rect.width / 2));

                if (previousMove > 0) {
                    moveByValue.value += previousMove
                }

                if (previousMove < 0) {
                    moveByValue.value -= previousMove
                }

                if (rect.left > (window.innerWidth / 2)) {
                    moveByValue.value = -1 * moveByValue.value
                }
            }
        }

        const goToPrevStep = function () {
            if (step.value <= 0) {
                return
            }

            step.value -= 1
        }

        const goToNextStep = function (saveValue: boolean = false) {
            if (step.value >= topics.length) {
                return
            }

            mobileVoted.value = 0;

            if (saveValue) {
                votedTopics.push(topics[step.value])
            }

            step.value += 1

            if (ratingStripe.value) {
                animateElement.value = true
                setTimeout(() => {
                    animateElement.value = false
                }, 1000)
            }
        }

        const onSubmitScore = async function () {
            if (votedTopics.length < 1) {
                useMainStore().addFlashMessage({text: 'Žádné hlasy k uložení', type: 'error'})
                return
            }

            if (multiClickPrevention) {
                return
            }

            multiClickPrevention = true

            voteProcessing.value = true
            voteSuccess.value = false

            try {
                const votePromises = [... new Set(votedTopics)]
                    .map(async (voteType: VoteTypeEnum) => {
                        const storyVoteData = {
                            story: {
                                connect: {
                                    id: props.story?.id
                                }
                            },
                            score: votes[voteType] ?? 0,
                            type: voteType
                        } as VoteCreateInput

                        const createOrderResult = await getSdk(graphqlClient).createVote({data: storyVoteData})
                        return createOrderResult.createVote.ok ? Promise.resolve() : Promise.reject();
                    })

                Promise.all(votePromises)
                    .then(() => {
                        useMainStore().addFlashMessage({
                            text: 'Uloženo',
                            type: 'success'
                        })
                        voteSuccess.value = true
                        success.value = true
                        useMainStore().markStoryVoted(props.story?.id)
                    })
                    .catch(() => {
                        useMainStore().addFlashMessage({
                            text: 'Uložení selhalo',
                            type: 'error'
                        })
                        voteError.value = true
                    })

            } catch (e) {
                console.error(e)
                useMainStore().addFlashMessage({text: 'Uložení selhalo', type: 'error'})
                voteError.value = true
            } finally {
                voteProcessing.value = false
            }
        }

        const isVoteHoveredOrActive = (item: RatingItem, topic: VoteTypeEnum): boolean => {
            if (hoveredScore.value === 0) {
                // no hover is active
                return (votes[topic] ?? 0) === item.id;
            }

            return hoveredScore.value === item.id;
        }

        const votedForTopic = computed((): boolean => {
            const topic = topics[step.value]
            const votedScore = votes[topic] ?? 0
            return votedScore > 0
        })
        const averageSubmittedScore = computed((): string => {
            const votedScores = votedTopics.map((topic) => votes[topic] ?? 0);

            const sum = votedScores.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const avg = sum / votedScores.length;

            return avg?.toFixed(1)
        })

        const averageSubmittedScoreStrokes = computed((): string => {
            const averageScore = parseFloat(averageSubmittedScore.value)
            const averageScorePercentage = Math.round(averageScore * 10)

            if (averageScorePercentage >= 100) {
                return '100';
            }

            return `${averageScorePercentage} 1000`
        })

        const overallVoteLevel = computed((): RatingItem => {
            const value = Math.floor(parseFloat(averageSubmittedScore.value))

            return ratingItems[value - 1]
        })

        return {
            success, topics, step,
            voteError,
            voteSuccess,
            voteProcessing,
            ratingItems,
            ratingStripe,
            moveByValue,
            animateElement,
            votes,
            hoveredScore,
            goToPrevStep,
            goToNextStep,
            onSubmitScore,
            storeVote,
            isVoteHoveredOrActive,
            votedForTopic,
            averageSubmittedScore,
            averageSubmittedScoreStrokes,
            votedTopics,
            overallVoteLevel,
            mobileVoted,
            handleScroll,
            mobileScrollbarItem,
            selectByTap
        }
    }
}
