// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  /** Json custom scalar type */
  Json: any;
  UUID: any;
};

export type Client = {
  __typename?: 'Client';
  _meta: Maybe<ClientMeta>;
  id: Scalars['UUID'];
  label: Scalars['String'];
  paginateStories: StoryConnection;
  stories: Array<Story>;
  storiesByBlocks: Maybe<Story>;
  storiesByCredits: Maybe<Story>;
  storiesByMetrics: Maybe<Story>;
  storiesByPartialScore: Maybe<Story>;
  storiesByScore: Maybe<Story>;
};


export type ClientPaginateStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type ClientStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
};


export type ClientStoriesByBlocksArgs = {
  by: ClientStoriesByBlocksUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type ClientStoriesByCreditsArgs = {
  by: ClientStoriesByCreditsUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type ClientStoriesByMetricsArgs = {
  by: ClientStoriesByMetricsUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type ClientStoriesByPartialScoreArgs = {
  by: ClientStoriesByPartialScoreUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type ClientStoriesByScoreArgs = {
  by: ClientStoriesByScoreUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  node: Client;
};

export type ClientMeta = {
  __typename?: 'ClientMeta';
  id: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
  stories: Maybe<FieldMeta>;
};

export type ClientOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
};

export type ClientStoriesByBlocksUniqueWhere = {
  blocks: InputMaybe<ContentBlockUniqueWhere>;
};

export type ClientStoriesByCreditsUniqueWhere = {
  credits: InputMaybe<CreditUniqueWhere>;
};

export type ClientStoriesByMetricsUniqueWhere = {
  metrics: InputMaybe<MetricUniqueWhere>;
};

export type ClientStoriesByPartialScoreUniqueWhere = {
  partialScore: InputMaybe<StoryPartialScoreUniqueWhere>;
};

export type ClientStoriesByScoreUniqueWhere = {
  score: InputMaybe<StoryScoreUniqueWhere>;
};

export type ClientUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  label: InputMaybe<Scalars['String']>;
  stories: InputMaybe<StoryUniqueWhere>;
};

export type ClientWhere = {
  and: InputMaybe<Array<InputMaybe<ClientWhere>>>;
  id: InputMaybe<UuidCondition>;
  label: InputMaybe<StringCondition>;
  not: InputMaybe<ClientWhere>;
  or: InputMaybe<Array<InputMaybe<ClientWhere>>>;
  stories: InputMaybe<StoryWhere>;
};

export type Content = {
  __typename?: 'Content';
  _meta: Maybe<ContentMeta>;
  contentBlock: Maybe<ContentBlock>;
  id: Scalars['UUID'];
  imageWithText: Maybe<ImageWithText>;
  paginateParts: ContentPartConnection;
  parts: Array<ContentPart>;
  partsByReferences: Maybe<ContentPart>;
  videoWithText: Maybe<VideoWithText>;
};


export type ContentContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
};


export type ContentImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
};


export type ContentPaginatePartsArgs = {
  filter: InputMaybe<ContentPartWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type ContentPartsArgs = {
  filter: InputMaybe<ContentPartWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
};


export type ContentPartsByReferencesArgs = {
  by: ContentPartsByReferencesUniqueWhere;
  filter: InputMaybe<ContentPartWhere>;
};


export type ContentVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  _meta: Maybe<ContentBlockMeta>;
  alt: Maybe<Scalars['String']>;
  bgColor: Maybe<ContentBlockBackground>;
  content: Maybe<Content>;
  id: Scalars['UUID'];
  image: Maybe<Image>;
  imageWithText: Maybe<ImageWithText>;
  link: Maybe<Link>;
  order: Scalars['Int'];
  primaryText: Maybe<Scalars['String']>;
  quotation: Maybe<Quotation>;
  quotationWithImage: Maybe<QuotationWithImage>;
  story: Maybe<Story>;
  type: ContentBlockType;
  videoWithText: Maybe<VideoWithText>;
};


export type ContentBlockContentArgs = {
  filter: InputMaybe<ContentWhere>;
};


export type ContentBlockImageArgs = {
  filter: InputMaybe<ImageWhere>;
};


export type ContentBlockImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
};


export type ContentBlockLinkArgs = {
  filter: InputMaybe<LinkWhere>;
};


export type ContentBlockQuotationArgs = {
  filter: InputMaybe<QuotationWhere>;
};


export type ContentBlockQuotationWithImageArgs = {
  filter: InputMaybe<QuotationWithImageWhere>;
};


export type ContentBlockStoryArgs = {
  filter: InputMaybe<StoryWhere>;
};


export type ContentBlockVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
};

export enum ContentBlockBackground {
  Dark = 'dark',
  Lighter = 'lighter'
}

export type ContentBlockBackgroundCondition = {
  and: InputMaybe<Array<ContentBlockBackgroundCondition>>;
  eq: InputMaybe<ContentBlockBackground>;
  gt: InputMaybe<ContentBlockBackground>;
  gte: InputMaybe<ContentBlockBackground>;
  in: InputMaybe<Array<ContentBlockBackground>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<ContentBlockBackground>;
  lte: InputMaybe<ContentBlockBackground>;
  not: InputMaybe<ContentBlockBackgroundCondition>;
  notEq: InputMaybe<ContentBlockBackground>;
  notIn: InputMaybe<Array<ContentBlockBackground>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<ContentBlockBackgroundCondition>>;
};

export type ContentBlockConnection = {
  __typename?: 'ContentBlockConnection';
  edges: Array<ContentBlockEdge>;
  pageInfo: PageInfo;
};

export type ContentBlockEdge = {
  __typename?: 'ContentBlockEdge';
  node: ContentBlock;
};

export type ContentBlockMeta = {
  __typename?: 'ContentBlockMeta';
  alt: Maybe<FieldMeta>;
  bgColor: Maybe<FieldMeta>;
  content: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  image: Maybe<FieldMeta>;
  imageWithText: Maybe<FieldMeta>;
  link: Maybe<FieldMeta>;
  order: Maybe<FieldMeta>;
  primaryText: Maybe<FieldMeta>;
  quotation: Maybe<FieldMeta>;
  quotationWithImage: Maybe<FieldMeta>;
  story: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
  videoWithText: Maybe<FieldMeta>;
};

export type ContentBlockOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  alt: InputMaybe<OrderDirection>;
  bgColor: InputMaybe<OrderDirection>;
  content: InputMaybe<ContentOrderBy>;
  id: InputMaybe<OrderDirection>;
  image: InputMaybe<ImageOrderBy>;
  imageWithText: InputMaybe<ImageWithTextOrderBy>;
  link: InputMaybe<LinkOrderBy>;
  order: InputMaybe<OrderDirection>;
  primaryText: InputMaybe<OrderDirection>;
  quotation: InputMaybe<QuotationOrderBy>;
  quotationWithImage: InputMaybe<QuotationWithImageOrderBy>;
  story: InputMaybe<StoryOrderBy>;
  type: InputMaybe<OrderDirection>;
  videoWithText: InputMaybe<VideoWithTextOrderBy>;
};

export enum ContentBlockType {
  ContentSection = 'contentSection',
  ImageWithText = 'imageWithText',
  Quotation = 'quotation',
  QuotationWithImage = 'quotationWithImage',
  VideoWithText = 'videoWithText'
}

export type ContentBlockTypeCondition = {
  and: InputMaybe<Array<ContentBlockTypeCondition>>;
  eq: InputMaybe<ContentBlockType>;
  gt: InputMaybe<ContentBlockType>;
  gte: InputMaybe<ContentBlockType>;
  in: InputMaybe<Array<ContentBlockType>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<ContentBlockType>;
  lte: InputMaybe<ContentBlockType>;
  not: InputMaybe<ContentBlockTypeCondition>;
  notEq: InputMaybe<ContentBlockType>;
  notIn: InputMaybe<Array<ContentBlockType>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<ContentBlockTypeCondition>>;
};

export type ContentBlockUniqueWhere = {
  content: InputMaybe<ContentUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
  imageWithText: InputMaybe<ImageWithTextUniqueWhere>;
  link: InputMaybe<LinkUniqueWhere>;
  quotation: InputMaybe<QuotationUniqueWhere>;
  quotationWithImage: InputMaybe<QuotationWithImageUniqueWhere>;
  videoWithText: InputMaybe<VideoWithTextUniqueWhere>;
};

export type ContentBlockWhere = {
  alt: InputMaybe<StringCondition>;
  and: InputMaybe<Array<InputMaybe<ContentBlockWhere>>>;
  bgColor: InputMaybe<ContentBlockBackgroundCondition>;
  content: InputMaybe<ContentWhere>;
  id: InputMaybe<UuidCondition>;
  image: InputMaybe<ImageWhere>;
  imageWithText: InputMaybe<ImageWithTextWhere>;
  link: InputMaybe<LinkWhere>;
  not: InputMaybe<ContentBlockWhere>;
  or: InputMaybe<Array<InputMaybe<ContentBlockWhere>>>;
  order: InputMaybe<IntCondition>;
  primaryText: InputMaybe<StringCondition>;
  quotation: InputMaybe<QuotationWhere>;
  quotationWithImage: InputMaybe<QuotationWithImageWhere>;
  story: InputMaybe<StoryWhere>;
  type: InputMaybe<ContentBlockTypeCondition>;
  videoWithText: InputMaybe<VideoWithTextWhere>;
};

export type ContentConnection = {
  __typename?: 'ContentConnection';
  edges: Array<ContentEdge>;
  pageInfo: PageInfo;
};

export type ContentEdge = {
  __typename?: 'ContentEdge';
  node: Content;
};

export type ContentMeta = {
  __typename?: 'ContentMeta';
  contentBlock: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  imageWithText: Maybe<FieldMeta>;
  parts: Maybe<FieldMeta>;
  videoWithText: Maybe<FieldMeta>;
};

export type ContentOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  contentBlock: InputMaybe<ContentBlockOrderBy>;
  id: InputMaybe<OrderDirection>;
  imageWithText: InputMaybe<ImageWithTextOrderBy>;
  videoWithText: InputMaybe<VideoWithTextOrderBy>;
};

export type ContentPart = {
  __typename?: 'ContentPart';
  _meta: Maybe<ContentPartMeta>;
  content: Maybe<Content>;
  id: Scalars['UUID'];
  json: Scalars['String'];
  order: Scalars['Int'];
  paginateReferences: ContentReferenceConnection;
  references: Array<ContentReference>;
  referencesByTarget: Maybe<ContentReference>;
};


export type ContentPartContentArgs = {
  filter: InputMaybe<ContentWhere>;
};


export type ContentPartPaginateReferencesArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type ContentPartReferencesArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
};


export type ContentPartReferencesByTargetArgs = {
  by: ContentPartReferencesByTargetUniqueWhere;
  filter: InputMaybe<ContentReferenceWhere>;
};

export type ContentPartConnection = {
  __typename?: 'ContentPartConnection';
  edges: Array<ContentPartEdge>;
  pageInfo: PageInfo;
};

export type ContentPartEdge = {
  __typename?: 'ContentPartEdge';
  node: ContentPart;
};

export type ContentPartMeta = {
  __typename?: 'ContentPartMeta';
  content: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  json: Maybe<FieldMeta>;
  order: Maybe<FieldMeta>;
  references: Maybe<FieldMeta>;
};

export type ContentPartOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  content: InputMaybe<ContentOrderBy>;
  id: InputMaybe<OrderDirection>;
  json: InputMaybe<OrderDirection>;
  order: InputMaybe<OrderDirection>;
};

export type ContentPartReferencesByTargetUniqueWhere = {
  target: InputMaybe<LinkUniqueWhere>;
};

export type ContentPartUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  references: InputMaybe<ContentReferenceUniqueWhere>;
};

export type ContentPartWhere = {
  and: InputMaybe<Array<InputMaybe<ContentPartWhere>>>;
  content: InputMaybe<ContentWhere>;
  id: InputMaybe<UuidCondition>;
  json: InputMaybe<StringCondition>;
  not: InputMaybe<ContentPartWhere>;
  or: InputMaybe<Array<InputMaybe<ContentPartWhere>>>;
  order: InputMaybe<IntCondition>;
  references: InputMaybe<ContentReferenceWhere>;
};

export type ContentPartsByReferencesUniqueWhere = {
  references: InputMaybe<ContentReferenceUniqueWhere>;
};

export type ContentReference = {
  __typename?: 'ContentReference';
  _meta: Maybe<ContentReferenceMeta>;
  contentPart: Maybe<ContentPart>;
  id: Scalars['UUID'];
  target: Maybe<Link>;
  type: ContentReferenceType;
};


export type ContentReferenceContentPartArgs = {
  filter: InputMaybe<ContentPartWhere>;
};


export type ContentReferenceTargetArgs = {
  filter: InputMaybe<LinkWhere>;
};

export type ContentReferenceConnection = {
  __typename?: 'ContentReferenceConnection';
  edges: Array<ContentReferenceEdge>;
  pageInfo: PageInfo;
};

export type ContentReferenceEdge = {
  __typename?: 'ContentReferenceEdge';
  node: ContentReference;
};

export type ContentReferenceMeta = {
  __typename?: 'ContentReferenceMeta';
  contentPart: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  target: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
};

export type ContentReferenceOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  contentPart: InputMaybe<ContentPartOrderBy>;
  id: InputMaybe<OrderDirection>;
  target: InputMaybe<LinkOrderBy>;
  type: InputMaybe<OrderDirection>;
};

export enum ContentReferenceType {
  Link = 'link'
}

export type ContentReferenceTypeCondition = {
  and: InputMaybe<Array<ContentReferenceTypeCondition>>;
  eq: InputMaybe<ContentReferenceType>;
  gt: InputMaybe<ContentReferenceType>;
  gte: InputMaybe<ContentReferenceType>;
  in: InputMaybe<Array<ContentReferenceType>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<ContentReferenceType>;
  lte: InputMaybe<ContentReferenceType>;
  not: InputMaybe<ContentReferenceTypeCondition>;
  notEq: InputMaybe<ContentReferenceType>;
  notIn: InputMaybe<Array<ContentReferenceType>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<ContentReferenceTypeCondition>>;
};

export type ContentReferenceUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  target: InputMaybe<LinkUniqueWhere>;
};

export type ContentReferenceWhere = {
  and: InputMaybe<Array<InputMaybe<ContentReferenceWhere>>>;
  contentPart: InputMaybe<ContentPartWhere>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<ContentReferenceWhere>;
  or: InputMaybe<Array<InputMaybe<ContentReferenceWhere>>>;
  target: InputMaybe<LinkWhere>;
  type: InputMaybe<ContentReferenceTypeCondition>;
};

export type ContentUniqueWhere = {
  contentBlock: InputMaybe<ContentBlockUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
  imageWithText: InputMaybe<ImageWithTextUniqueWhere>;
  parts: InputMaybe<ContentPartUniqueWhere>;
  videoWithText: InputMaybe<VideoWithTextUniqueWhere>;
};

export type ContentWhere = {
  and: InputMaybe<Array<InputMaybe<ContentWhere>>>;
  contentBlock: InputMaybe<ContentBlockWhere>;
  id: InputMaybe<UuidCondition>;
  imageWithText: InputMaybe<ImageWithTextWhere>;
  not: InputMaybe<ContentWhere>;
  or: InputMaybe<Array<InputMaybe<ContentWhere>>>;
  parts: InputMaybe<ContentPartWhere>;
  videoWithText: InputMaybe<VideoWithTextWhere>;
};

export type Credit = {
  __typename?: 'Credit';
  _meta: Maybe<CreditMeta>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  role: Maybe<CreditRole>;
  story: Maybe<Story>;
};


export type CreditRoleArgs = {
  filter: InputMaybe<CreditRoleWhere>;
};


export type CreditStoryArgs = {
  filter: InputMaybe<StoryWhere>;
};

export type CreditConnection = {
  __typename?: 'CreditConnection';
  edges: Array<CreditEdge>;
  pageInfo: PageInfo;
};

export type CreditEdge = {
  __typename?: 'CreditEdge';
  node: Credit;
};

export type CreditMeta = {
  __typename?: 'CreditMeta';
  id: Maybe<FieldMeta>;
  name: Maybe<FieldMeta>;
  order: Maybe<FieldMeta>;
  role: Maybe<FieldMeta>;
  story: Maybe<FieldMeta>;
};

export type CreditOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  name: InputMaybe<OrderDirection>;
  order: InputMaybe<OrderDirection>;
  role: InputMaybe<CreditRoleOrderBy>;
  story: InputMaybe<StoryOrderBy>;
};

export type CreditRole = {
  __typename?: 'CreditRole';
  _meta: Maybe<CreditRoleMeta>;
  credits: Array<Credit>;
  id: Scalars['UUID'];
  label: Scalars['String'];
  paginateCredits: CreditConnection;
};


export type CreditRoleCreditsArgs = {
  filter: InputMaybe<CreditWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
};


export type CreditRolePaginateCreditsArgs = {
  filter: InputMaybe<CreditWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CreditRoleConnection = {
  __typename?: 'CreditRoleConnection';
  edges: Array<CreditRoleEdge>;
  pageInfo: PageInfo;
};

export type CreditRoleEdge = {
  __typename?: 'CreditRoleEdge';
  node: CreditRole;
};

export type CreditRoleMeta = {
  __typename?: 'CreditRoleMeta';
  credits: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
};

export type CreditRoleOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
};

export type CreditRoleUniqueWhere = {
  credits: InputMaybe<CreditUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
};

export type CreditRoleWhere = {
  and: InputMaybe<Array<InputMaybe<CreditRoleWhere>>>;
  credits: InputMaybe<CreditWhere>;
  id: InputMaybe<UuidCondition>;
  label: InputMaybe<StringCondition>;
  not: InputMaybe<CreditRoleWhere>;
  or: InputMaybe<Array<InputMaybe<CreditRoleWhere>>>;
};

export type CreditUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
};

export type CreditWhere = {
  and: InputMaybe<Array<InputMaybe<CreditWhere>>>;
  id: InputMaybe<UuidCondition>;
  name: InputMaybe<StringCondition>;
  not: InputMaybe<CreditWhere>;
  or: InputMaybe<Array<InputMaybe<CreditWhere>>>;
  order: InputMaybe<IntCondition>;
  role: InputMaybe<CreditRoleWhere>;
  story: InputMaybe<StoryWhere>;
};

export type DateCondition = {
  and: InputMaybe<Array<DateCondition>>;
  eq: InputMaybe<Scalars['Date']>;
  gt: InputMaybe<Scalars['Date']>;
  gte: InputMaybe<Scalars['Date']>;
  in: InputMaybe<Array<Scalars['Date']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['Date']>;
  lte: InputMaybe<Scalars['Date']>;
  not: InputMaybe<DateCondition>;
  notEq: InputMaybe<Scalars['Date']>;
  notIn: InputMaybe<Array<Scalars['Date']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<DateCondition>>;
};

export type DateTimeCondition = {
  and: InputMaybe<Array<DateTimeCondition>>;
  eq: InputMaybe<Scalars['DateTime']>;
  gt: InputMaybe<Scalars['DateTime']>;
  gte: InputMaybe<Scalars['DateTime']>;
  in: InputMaybe<Array<Scalars['DateTime']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['DateTime']>;
  lte: InputMaybe<Scalars['DateTime']>;
  not: InputMaybe<DateTimeCondition>;
  notEq: InputMaybe<Scalars['DateTime']>;
  notIn: InputMaybe<Array<Scalars['DateTime']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<DateTimeCondition>>;
};

export type FieldMeta = {
  __typename?: 'FieldMeta';
  readable: Maybe<Scalars['Boolean']>;
  updatable: Maybe<Scalars['Boolean']>;
};

export type File = {
  __typename?: 'File';
  _meta: Maybe<FileMeta>;
  description: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  label: Scalars['String'];
  linkedFrom: Array<Link>;
  name: Maybe<Scalars['String']>;
  paginateLinkedFrom: LinkConnection;
  size: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


export type FileLinkedFromArgs = {
  filter: InputMaybe<LinkWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
};


export type FilePaginateLinkedFromArgs = {
  filter: InputMaybe<LinkWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  node: File;
};

export type FileMeta = {
  __typename?: 'FileMeta';
  description: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
  linkedFrom: Maybe<FieldMeta>;
  name: Maybe<FieldMeta>;
  size: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
  url: Maybe<FieldMeta>;
};

export type FileOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  description: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
  name: InputMaybe<OrderDirection>;
  size: InputMaybe<OrderDirection>;
  type: InputMaybe<OrderDirection>;
  url: InputMaybe<OrderDirection>;
};

export type FileUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  linkedFrom: InputMaybe<LinkUniqueWhere>;
};

export type FileWhere = {
  and: InputMaybe<Array<InputMaybe<FileWhere>>>;
  description: InputMaybe<StringCondition>;
  id: InputMaybe<UuidCondition>;
  label: InputMaybe<StringCondition>;
  linkedFrom: InputMaybe<LinkWhere>;
  name: InputMaybe<StringCondition>;
  not: InputMaybe<FileWhere>;
  or: InputMaybe<Array<InputMaybe<FileWhere>>>;
  size: InputMaybe<IntCondition>;
  type: InputMaybe<StringCondition>;
  url: InputMaybe<StringCondition>;
};

export type FloatCondition = {
  and: InputMaybe<Array<FloatCondition>>;
  eq: InputMaybe<Scalars['Float']>;
  gt: InputMaybe<Scalars['Float']>;
  gte: InputMaybe<Scalars['Float']>;
  in: InputMaybe<Array<Scalars['Float']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['Float']>;
  lte: InputMaybe<Scalars['Float']>;
  not: InputMaybe<FloatCondition>;
  notEq: InputMaybe<Scalars['Float']>;
  notIn: InputMaybe<Array<Scalars['Float']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<FloatCondition>>;
};

export type Image = {
  __typename?: 'Image';
  _meta: Maybe<ImageMeta>;
  height: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  locales: Array<ImageLocale>;
  localesByLocale: Maybe<ImageLocale>;
  paginateLocales: ImageLocaleConnection;
  size: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
};


export type ImageLocalesArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
};


export type ImageLocalesByLocaleArgs = {
  by: ImageLocalesByLocaleUniqueWhere;
  filter: InputMaybe<ImageLocaleWhere>;
};


export type ImagePaginateLocalesArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  edges: Array<ImageEdge>;
  pageInfo: PageInfo;
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  node: Image;
};

export type ImageLocale = {
  __typename?: 'ImageLocale';
  _meta: Maybe<ImageLocaleMeta>;
  alt: Maybe<Scalars['String']>;
  base: Maybe<Image>;
  id: Scalars['UUID'];
  locale: Maybe<Locale>;
};


export type ImageLocaleBaseArgs = {
  filter: InputMaybe<ImageWhere>;
};


export type ImageLocaleLocaleArgs = {
  filter: InputMaybe<LocaleWhere>;
};

export type ImageLocaleConnection = {
  __typename?: 'ImageLocaleConnection';
  edges: Array<ImageLocaleEdge>;
  pageInfo: PageInfo;
};

export type ImageLocaleEdge = {
  __typename?: 'ImageLocaleEdge';
  node: ImageLocale;
};

export type ImageLocaleMeta = {
  __typename?: 'ImageLocaleMeta';
  alt: Maybe<FieldMeta>;
  base: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  locale: Maybe<FieldMeta>;
};

export type ImageLocaleOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  alt: InputMaybe<OrderDirection>;
  base: InputMaybe<ImageOrderBy>;
  id: InputMaybe<OrderDirection>;
  locale: InputMaybe<LocaleOrderBy>;
};

export type ImageLocaleUniqueWhere = {
  base: InputMaybe<ImageUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
  locale: InputMaybe<LocaleUniqueWhere>;
};

export type ImageLocaleWhere = {
  alt: InputMaybe<StringCondition>;
  and: InputMaybe<Array<InputMaybe<ImageLocaleWhere>>>;
  base: InputMaybe<ImageWhere>;
  id: InputMaybe<UuidCondition>;
  locale: InputMaybe<LocaleWhere>;
  not: InputMaybe<ImageLocaleWhere>;
  or: InputMaybe<Array<InputMaybe<ImageLocaleWhere>>>;
};

export type ImageLocalesByLocaleUniqueWhere = {
  locale: InputMaybe<LocaleUniqueWhere>;
};

export type ImageMeta = {
  __typename?: 'ImageMeta';
  height: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  locales: Maybe<FieldMeta>;
  size: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
  url: Maybe<FieldMeta>;
  width: Maybe<FieldMeta>;
};

export type ImageOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  height: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  size: InputMaybe<OrderDirection>;
  type: InputMaybe<OrderDirection>;
  url: InputMaybe<OrderDirection>;
  width: InputMaybe<OrderDirection>;
};

export type ImageUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  locales: InputMaybe<ImageLocaleUniqueWhere>;
};

export type ImageWhere = {
  and: InputMaybe<Array<InputMaybe<ImageWhere>>>;
  height: InputMaybe<IntCondition>;
  id: InputMaybe<UuidCondition>;
  locales: InputMaybe<ImageLocaleWhere>;
  not: InputMaybe<ImageWhere>;
  or: InputMaybe<Array<InputMaybe<ImageWhere>>>;
  size: InputMaybe<IntCondition>;
  type: InputMaybe<StringCondition>;
  url: InputMaybe<StringCondition>;
  width: InputMaybe<IntCondition>;
};

export type ImageWithText = {
  __typename?: 'ImageWithText';
  _meta: Maybe<ImageWithTextMeta>;
  content: Maybe<Content>;
  contentBlock: Maybe<ContentBlock>;
  id: Scalars['UUID'];
  image: Maybe<Image>;
};


export type ImageWithTextContentArgs = {
  filter: InputMaybe<ContentWhere>;
};


export type ImageWithTextContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
};


export type ImageWithTextImageArgs = {
  filter: InputMaybe<ImageWhere>;
};

export type ImageWithTextConnection = {
  __typename?: 'ImageWithTextConnection';
  edges: Array<ImageWithTextEdge>;
  pageInfo: PageInfo;
};

export type ImageWithTextEdge = {
  __typename?: 'ImageWithTextEdge';
  node: ImageWithText;
};

export type ImageWithTextMeta = {
  __typename?: 'ImageWithTextMeta';
  content: Maybe<FieldMeta>;
  contentBlock: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  image: Maybe<FieldMeta>;
};

export type ImageWithTextOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  content: InputMaybe<ContentOrderBy>;
  contentBlock: InputMaybe<ContentBlockOrderBy>;
  id: InputMaybe<OrderDirection>;
  image: InputMaybe<ImageOrderBy>;
};

export type ImageWithTextUniqueWhere = {
  content: InputMaybe<ContentUniqueWhere>;
  contentBlock: InputMaybe<ContentBlockUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
};

export type ImageWithTextWhere = {
  and: InputMaybe<Array<InputMaybe<ImageWithTextWhere>>>;
  content: InputMaybe<ContentWhere>;
  contentBlock: InputMaybe<ContentBlockWhere>;
  id: InputMaybe<UuidCondition>;
  image: InputMaybe<ImageWhere>;
  not: InputMaybe<ImageWithTextWhere>;
  or: InputMaybe<Array<InputMaybe<ImageWithTextWhere>>>;
};

export type Info = {
  __typename?: 'Info';
  description: Maybe<Scalars['String']>;
};

export type IntCondition = {
  and: InputMaybe<Array<IntCondition>>;
  eq: InputMaybe<Scalars['Int']>;
  gt: InputMaybe<Scalars['Int']>;
  gte: InputMaybe<Scalars['Int']>;
  in: InputMaybe<Array<Scalars['Int']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['Int']>;
  lte: InputMaybe<Scalars['Int']>;
  not: InputMaybe<IntCondition>;
  notEq: InputMaybe<Scalars['Int']>;
  notIn: InputMaybe<Array<Scalars['Int']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<IntCondition>>;
};

export type Link = {
  __typename?: 'Link';
  _meta: Maybe<LinkMeta>;
  file: Maybe<File>;
  id: Scalars['UUID'];
  type: LinkEnum;
  url: Maybe<Scalars['String']>;
};


export type LinkFileArgs = {
  filter: InputMaybe<FileWhere>;
};

export type LinkConnection = {
  __typename?: 'LinkConnection';
  edges: Array<LinkEdge>;
  pageInfo: PageInfo;
};

export type LinkEdge = {
  __typename?: 'LinkEdge';
  node: Link;
};

export enum LinkEnum {
  File = 'file',
  Url = 'url'
}

export type LinkEnumCondition = {
  and: InputMaybe<Array<LinkEnumCondition>>;
  eq: InputMaybe<LinkEnum>;
  gt: InputMaybe<LinkEnum>;
  gte: InputMaybe<LinkEnum>;
  in: InputMaybe<Array<LinkEnum>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<LinkEnum>;
  lte: InputMaybe<LinkEnum>;
  not: InputMaybe<LinkEnumCondition>;
  notEq: InputMaybe<LinkEnum>;
  notIn: InputMaybe<Array<LinkEnum>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<LinkEnumCondition>>;
};

export type LinkMeta = {
  __typename?: 'LinkMeta';
  file: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
  url: Maybe<FieldMeta>;
};

export type LinkOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  file: InputMaybe<FileOrderBy>;
  id: InputMaybe<OrderDirection>;
  type: InputMaybe<OrderDirection>;
  url: InputMaybe<OrderDirection>;
};

export type LinkUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
};

export type LinkWhere = {
  and: InputMaybe<Array<InputMaybe<LinkWhere>>>;
  file: InputMaybe<FileWhere>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<LinkWhere>;
  or: InputMaybe<Array<InputMaybe<LinkWhere>>>;
  type: InputMaybe<LinkEnumCondition>;
  url: InputMaybe<StringCondition>;
};

export type Locale = {
  __typename?: 'Locale';
  _meta: Maybe<LocaleMeta>;
  code: Scalars['String'];
  id: Scalars['UUID'];
  images: Array<ImageLocale>;
  imagesByBase: Maybe<ImageLocale>;
  label: Scalars['String'];
  paginateImages: ImageLocaleConnection;
};


export type LocaleImagesArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
};


export type LocaleImagesByBaseArgs = {
  by: LocaleImagesByBaseUniqueWhere;
  filter: InputMaybe<ImageLocaleWhere>;
};


export type LocalePaginateImagesArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type LocaleConnection = {
  __typename?: 'LocaleConnection';
  edges: Array<LocaleEdge>;
  pageInfo: PageInfo;
};

export type LocaleEdge = {
  __typename?: 'LocaleEdge';
  node: Locale;
};

export type LocaleImagesByBaseUniqueWhere = {
  base: InputMaybe<ImageUniqueWhere>;
};

export type LocaleMeta = {
  __typename?: 'LocaleMeta';
  code: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  images: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
};

export type LocaleOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  code: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
};

export type LocaleUniqueWhere = {
  code: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  images: InputMaybe<ImageLocaleUniqueWhere>;
};

export type LocaleWhere = {
  and: InputMaybe<Array<InputMaybe<LocaleWhere>>>;
  code: InputMaybe<StringCondition>;
  id: InputMaybe<UuidCondition>;
  images: InputMaybe<ImageLocaleWhere>;
  label: InputMaybe<StringCondition>;
  not: InputMaybe<LocaleWhere>;
  or: InputMaybe<Array<InputMaybe<LocaleWhere>>>;
};

export type Metric = {
  __typename?: 'Metric';
  _meta: Maybe<MetricMeta>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  story: Maybe<Story>;
  value: Scalars['String'];
};


export type MetricStoryArgs = {
  filter: InputMaybe<StoryWhere>;
};

export type MetricConnection = {
  __typename?: 'MetricConnection';
  edges: Array<MetricEdge>;
  pageInfo: PageInfo;
};

export type MetricEdge = {
  __typename?: 'MetricEdge';
  node: Metric;
};

export type MetricMeta = {
  __typename?: 'MetricMeta';
  id: Maybe<FieldMeta>;
  name: Maybe<FieldMeta>;
  order: Maybe<FieldMeta>;
  story: Maybe<FieldMeta>;
  value: Maybe<FieldMeta>;
};

export type MetricOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  name: InputMaybe<OrderDirection>;
  order: InputMaybe<OrderDirection>;
  story: InputMaybe<StoryOrderBy>;
  value: InputMaybe<OrderDirection>;
};

export type MetricUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
};

export type MetricWhere = {
  and: InputMaybe<Array<InputMaybe<MetricWhere>>>;
  id: InputMaybe<UuidCondition>;
  name: InputMaybe<StringCondition>;
  not: InputMaybe<MetricWhere>;
  or: InputMaybe<Array<InputMaybe<MetricWhere>>>;
  order: InputMaybe<IntCondition>;
  story: InputMaybe<StoryWhere>;
  value: InputMaybe<StringCondition>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createVote: VoteCreateResult;
  generateReadUrl: S3SignedRead;
  generateUploadUrl: S3SignedUpload;
  query: Query;
  transaction: MutationTransaction;
};


export type MutationCreateVoteArgs = {
  data: VoteCreateInput;
};


export type MutationGenerateReadUrlArgs = {
  expiration: InputMaybe<Scalars['Int']>;
  objectKey: Scalars['String'];
};


export type MutationGenerateUploadUrlArgs = {
  contentType: Scalars['String'];
  expiration: InputMaybe<Scalars['Int']>;
  prefix: InputMaybe<Scalars['String']>;
};


export type MutationTransactionArgs = {
  options: InputMaybe<MutationTransactionOptions>;
};

export type MutationResult = {
  errorMessage: Maybe<Scalars['String']>;
  errors: Array<_MutationError>;
  ok: Scalars['Boolean'];
};

export type MutationTransaction = {
  __typename?: 'MutationTransaction';
  createVote: VoteCreateResult;
  errorMessage: Maybe<Scalars['String']>;
  errors: Array<_MutationError>;
  ok: Scalars['Boolean'];
  query: Maybe<Query>;
  validation: _ValidationResult;
};


export type MutationTransactionCreateVoteArgs = {
  data: VoteCreateInput;
};

export type MutationTransactionOptions = {
  deferForeignKeyConstraints: InputMaybe<Scalars['Boolean']>;
};

export enum OrderDirection {
  Asc = 'asc',
  AscNullsFirst = 'ascNullsFirst',
  Desc = 'desc',
  DescNullsLast = 'descNullsLast'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  _info: Maybe<Info>;
  getClient: Maybe<Client>;
  getContent: Maybe<Content>;
  getContentBlock: Maybe<ContentBlock>;
  getContentPart: Maybe<ContentPart>;
  getContentReference: Maybe<ContentReference>;
  getCredit: Maybe<Credit>;
  getCreditRole: Maybe<CreditRole>;
  getFile: Maybe<File>;
  getImage: Maybe<Image>;
  getImageLocale: Maybe<ImageLocale>;
  getImageWithText: Maybe<ImageWithText>;
  getLink: Maybe<Link>;
  getLocale: Maybe<Locale>;
  getMetric: Maybe<Metric>;
  getQuotation: Maybe<Quotation>;
  getQuotationWithImage: Maybe<QuotationWithImage>;
  getSetting: Maybe<Setting>;
  getSettingItem: Maybe<SettingItem>;
  getStatus: Maybe<Status>;
  getStory: Maybe<Story>;
  getStoryPartialScore: Maybe<StoryPartialScore>;
  getStoryScore: Maybe<StoryScore>;
  getTag: Maybe<Tag>;
  getVideo: Maybe<Video>;
  getVideoWithText: Maybe<VideoWithText>;
  listClient: Array<Client>;
  listContent: Array<Content>;
  listContentBlock: Array<ContentBlock>;
  listContentPart: Array<ContentPart>;
  listContentReference: Array<ContentReference>;
  listCredit: Array<Credit>;
  listCreditRole: Array<CreditRole>;
  listFile: Array<File>;
  listImage: Array<Image>;
  listImageLocale: Array<ImageLocale>;
  listImageWithText: Array<ImageWithText>;
  listLink: Array<Link>;
  listLocale: Array<Locale>;
  listMetric: Array<Metric>;
  listQuotation: Array<Quotation>;
  listQuotationWithImage: Array<QuotationWithImage>;
  listSetting: Array<Setting>;
  listSettingItem: Array<SettingItem>;
  listStatus: Array<Status>;
  listStory: Array<Story>;
  listStoryPartialScore: Array<StoryPartialScore>;
  listStoryScore: Array<StoryScore>;
  listTag: Array<Tag>;
  listVideo: Array<Video>;
  listVideoWithText: Array<VideoWithText>;
  paginateClient: ClientConnection;
  paginateContent: ContentConnection;
  paginateContentBlock: ContentBlockConnection;
  paginateContentPart: ContentPartConnection;
  paginateContentReference: ContentReferenceConnection;
  paginateCredit: CreditConnection;
  paginateCreditRole: CreditRoleConnection;
  paginateFile: FileConnection;
  paginateImage: ImageConnection;
  paginateImageLocale: ImageLocaleConnection;
  paginateImageWithText: ImageWithTextConnection;
  paginateLink: LinkConnection;
  paginateLocale: LocaleConnection;
  paginateMetric: MetricConnection;
  paginateQuotation: QuotationConnection;
  paginateQuotationWithImage: QuotationWithImageConnection;
  paginateSetting: SettingConnection;
  paginateSettingItem: SettingItemConnection;
  paginateStatus: StatusConnection;
  paginateStory: StoryConnection;
  paginateStoryPartialScore: StoryPartialScoreConnection;
  paginateStoryScore: StoryScoreConnection;
  paginateTag: TagConnection;
  paginateVideo: VideoConnection;
  paginateVideoWithText: VideoWithTextConnection;
  s3DummyQuery: Maybe<Scalars['String']>;
  schema: Maybe<_Schema>;
  transaction: Maybe<QueryTransaction>;
  validateCreateVote: _ValidationResult;
};


export type QueryGetClientArgs = {
  by: ClientUniqueWhere;
  filter: InputMaybe<ClientWhere>;
};


export type QueryGetContentArgs = {
  by: ContentUniqueWhere;
  filter: InputMaybe<ContentWhere>;
};


export type QueryGetContentBlockArgs = {
  by: ContentBlockUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type QueryGetContentPartArgs = {
  by: ContentPartUniqueWhere;
  filter: InputMaybe<ContentPartWhere>;
};


export type QueryGetContentReferenceArgs = {
  by: ContentReferenceUniqueWhere;
  filter: InputMaybe<ContentReferenceWhere>;
};


export type QueryGetCreditArgs = {
  by: CreditUniqueWhere;
  filter: InputMaybe<CreditWhere>;
};


export type QueryGetCreditRoleArgs = {
  by: CreditRoleUniqueWhere;
  filter: InputMaybe<CreditRoleWhere>;
};


export type QueryGetFileArgs = {
  by: FileUniqueWhere;
  filter: InputMaybe<FileWhere>;
};


export type QueryGetImageArgs = {
  by: ImageUniqueWhere;
  filter: InputMaybe<ImageWhere>;
};


export type QueryGetImageLocaleArgs = {
  by: ImageLocaleUniqueWhere;
  filter: InputMaybe<ImageLocaleWhere>;
};


export type QueryGetImageWithTextArgs = {
  by: ImageWithTextUniqueWhere;
  filter: InputMaybe<ImageWithTextWhere>;
};


export type QueryGetLinkArgs = {
  by: LinkUniqueWhere;
  filter: InputMaybe<LinkWhere>;
};


export type QueryGetLocaleArgs = {
  by: LocaleUniqueWhere;
  filter: InputMaybe<LocaleWhere>;
};


export type QueryGetMetricArgs = {
  by: MetricUniqueWhere;
  filter: InputMaybe<MetricWhere>;
};


export type QueryGetQuotationArgs = {
  by: QuotationUniqueWhere;
  filter: InputMaybe<QuotationWhere>;
};


export type QueryGetQuotationWithImageArgs = {
  by: QuotationWithImageUniqueWhere;
  filter: InputMaybe<QuotationWithImageWhere>;
};


export type QueryGetSettingArgs = {
  by: SettingUniqueWhere;
  filter: InputMaybe<SettingWhere>;
};


export type QueryGetSettingItemArgs = {
  by: SettingItemUniqueWhere;
  filter: InputMaybe<SettingItemWhere>;
};


export type QueryGetStatusArgs = {
  by: StatusUniqueWhere;
  filter: InputMaybe<StatusWhere>;
};


export type QueryGetStoryArgs = {
  by: StoryUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type QueryGetStoryPartialScoreArgs = {
  by: StoryPartialScoreUniqueWhere;
  filter: InputMaybe<StoryPartialScoreWhere>;
};


export type QueryGetStoryScoreArgs = {
  by: StoryScoreUniqueWhere;
  filter: InputMaybe<StoryScoreWhere>;
};


export type QueryGetTagArgs = {
  by: TagUniqueWhere;
  filter: InputMaybe<TagWhere>;
};


export type QueryGetVideoArgs = {
  by: VideoUniqueWhere;
  filter: InputMaybe<VideoWhere>;
};


export type QueryGetVideoWithTextArgs = {
  by: VideoWithTextUniqueWhere;
  filter: InputMaybe<VideoWithTextWhere>;
};


export type QueryListClientArgs = {
  filter: InputMaybe<ClientWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ClientOrderBy>>;
};


export type QueryListContentArgs = {
  filter: InputMaybe<ContentWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentOrderBy>>;
};


export type QueryListContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
};


export type QueryListContentPartArgs = {
  filter: InputMaybe<ContentPartWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
};


export type QueryListContentReferenceArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
};


export type QueryListCreditArgs = {
  filter: InputMaybe<CreditWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
};


export type QueryListCreditRoleArgs = {
  filter: InputMaybe<CreditRoleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditRoleOrderBy>>;
};


export type QueryListFileArgs = {
  filter: InputMaybe<FileWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<FileOrderBy>>;
};


export type QueryListImageArgs = {
  filter: InputMaybe<ImageWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageOrderBy>>;
};


export type QueryListImageLocaleArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
};


export type QueryListImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageWithTextOrderBy>>;
};


export type QueryListLinkArgs = {
  filter: InputMaybe<LinkWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
};


export type QueryListLocaleArgs = {
  filter: InputMaybe<LocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LocaleOrderBy>>;
};


export type QueryListMetricArgs = {
  filter: InputMaybe<MetricWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
};


export type QueryListQuotationArgs = {
  filter: InputMaybe<QuotationWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationOrderBy>>;
};


export type QueryListQuotationWithImageArgs = {
  filter: InputMaybe<QuotationWithImageWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationWithImageOrderBy>>;
};


export type QueryListSettingArgs = {
  filter: InputMaybe<SettingWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingOrderBy>>;
};


export type QueryListSettingItemArgs = {
  filter: InputMaybe<SettingItemWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
};


export type QueryListStatusArgs = {
  filter: InputMaybe<StatusWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StatusOrderBy>>;
};


export type QueryListStoryArgs = {
  filter: InputMaybe<StoryWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
};


export type QueryListStoryPartialScoreArgs = {
  filter: InputMaybe<StoryPartialScoreWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryPartialScoreOrderBy>>;
};


export type QueryListStoryScoreArgs = {
  filter: InputMaybe<StoryScoreWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryScoreOrderBy>>;
};


export type QueryListTagArgs = {
  filter: InputMaybe<TagWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
};


export type QueryListVideoArgs = {
  filter: InputMaybe<VideoWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoOrderBy>>;
};


export type QueryListVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoWithTextOrderBy>>;
};


export type QueryPaginateClientArgs = {
  filter: InputMaybe<ClientWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ClientOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateContentArgs = {
  filter: InputMaybe<ContentWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateContentPartArgs = {
  filter: InputMaybe<ContentPartWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateContentReferenceArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateCreditArgs = {
  filter: InputMaybe<CreditWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateCreditRoleArgs = {
  filter: InputMaybe<CreditRoleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditRoleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateFileArgs = {
  filter: InputMaybe<FileWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<FileOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateImageArgs = {
  filter: InputMaybe<ImageWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateImageLocaleArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageWithTextOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateLinkArgs = {
  filter: InputMaybe<LinkWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateLocaleArgs = {
  filter: InputMaybe<LocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateMetricArgs = {
  filter: InputMaybe<MetricWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateQuotationArgs = {
  filter: InputMaybe<QuotationWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateQuotationWithImageArgs = {
  filter: InputMaybe<QuotationWithImageWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationWithImageOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateSettingArgs = {
  filter: InputMaybe<SettingWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateSettingItemArgs = {
  filter: InputMaybe<SettingItemWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateStatusArgs = {
  filter: InputMaybe<StatusWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StatusOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateStoryArgs = {
  filter: InputMaybe<StoryWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateStoryPartialScoreArgs = {
  filter: InputMaybe<StoryPartialScoreWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryPartialScoreOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateStoryScoreArgs = {
  filter: InputMaybe<StoryScoreWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryScoreOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateTagArgs = {
  filter: InputMaybe<TagWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateVideoArgs = {
  filter: InputMaybe<VideoWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryPaginateVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoWithTextOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryValidateCreateVoteArgs = {
  data: VoteCreateInput;
};

export type QueryTransaction = {
  __typename?: 'QueryTransaction';
  getClient: Maybe<Client>;
  getContent: Maybe<Content>;
  getContentBlock: Maybe<ContentBlock>;
  getContentPart: Maybe<ContentPart>;
  getContentReference: Maybe<ContentReference>;
  getCredit: Maybe<Credit>;
  getCreditRole: Maybe<CreditRole>;
  getFile: Maybe<File>;
  getImage: Maybe<Image>;
  getImageLocale: Maybe<ImageLocale>;
  getImageWithText: Maybe<ImageWithText>;
  getLink: Maybe<Link>;
  getLocale: Maybe<Locale>;
  getMetric: Maybe<Metric>;
  getQuotation: Maybe<Quotation>;
  getQuotationWithImage: Maybe<QuotationWithImage>;
  getSetting: Maybe<Setting>;
  getSettingItem: Maybe<SettingItem>;
  getStatus: Maybe<Status>;
  getStory: Maybe<Story>;
  getStoryPartialScore: Maybe<StoryPartialScore>;
  getStoryScore: Maybe<StoryScore>;
  getTag: Maybe<Tag>;
  getVideo: Maybe<Video>;
  getVideoWithText: Maybe<VideoWithText>;
  listClient: Array<Client>;
  listContent: Array<Content>;
  listContentBlock: Array<ContentBlock>;
  listContentPart: Array<ContentPart>;
  listContentReference: Array<ContentReference>;
  listCredit: Array<Credit>;
  listCreditRole: Array<CreditRole>;
  listFile: Array<File>;
  listImage: Array<Image>;
  listImageLocale: Array<ImageLocale>;
  listImageWithText: Array<ImageWithText>;
  listLink: Array<Link>;
  listLocale: Array<Locale>;
  listMetric: Array<Metric>;
  listQuotation: Array<Quotation>;
  listQuotationWithImage: Array<QuotationWithImage>;
  listSetting: Array<Setting>;
  listSettingItem: Array<SettingItem>;
  listStatus: Array<Status>;
  listStory: Array<Story>;
  listStoryPartialScore: Array<StoryPartialScore>;
  listStoryScore: Array<StoryScore>;
  listTag: Array<Tag>;
  listVideo: Array<Video>;
  listVideoWithText: Array<VideoWithText>;
  paginateClient: ClientConnection;
  paginateContent: ContentConnection;
  paginateContentBlock: ContentBlockConnection;
  paginateContentPart: ContentPartConnection;
  paginateContentReference: ContentReferenceConnection;
  paginateCredit: CreditConnection;
  paginateCreditRole: CreditRoleConnection;
  paginateFile: FileConnection;
  paginateImage: ImageConnection;
  paginateImageLocale: ImageLocaleConnection;
  paginateImageWithText: ImageWithTextConnection;
  paginateLink: LinkConnection;
  paginateLocale: LocaleConnection;
  paginateMetric: MetricConnection;
  paginateQuotation: QuotationConnection;
  paginateQuotationWithImage: QuotationWithImageConnection;
  paginateSetting: SettingConnection;
  paginateSettingItem: SettingItemConnection;
  paginateStatus: StatusConnection;
  paginateStory: StoryConnection;
  paginateStoryPartialScore: StoryPartialScoreConnection;
  paginateStoryScore: StoryScoreConnection;
  paginateTag: TagConnection;
  paginateVideo: VideoConnection;
  paginateVideoWithText: VideoWithTextConnection;
  validateCreateVote: _ValidationResult;
};


export type QueryTransactionGetClientArgs = {
  by: ClientUniqueWhere;
  filter: InputMaybe<ClientWhere>;
};


export type QueryTransactionGetContentArgs = {
  by: ContentUniqueWhere;
  filter: InputMaybe<ContentWhere>;
};


export type QueryTransactionGetContentBlockArgs = {
  by: ContentBlockUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type QueryTransactionGetContentPartArgs = {
  by: ContentPartUniqueWhere;
  filter: InputMaybe<ContentPartWhere>;
};


export type QueryTransactionGetContentReferenceArgs = {
  by: ContentReferenceUniqueWhere;
  filter: InputMaybe<ContentReferenceWhere>;
};


export type QueryTransactionGetCreditArgs = {
  by: CreditUniqueWhere;
  filter: InputMaybe<CreditWhere>;
};


export type QueryTransactionGetCreditRoleArgs = {
  by: CreditRoleUniqueWhere;
  filter: InputMaybe<CreditRoleWhere>;
};


export type QueryTransactionGetFileArgs = {
  by: FileUniqueWhere;
  filter: InputMaybe<FileWhere>;
};


export type QueryTransactionGetImageArgs = {
  by: ImageUniqueWhere;
  filter: InputMaybe<ImageWhere>;
};


export type QueryTransactionGetImageLocaleArgs = {
  by: ImageLocaleUniqueWhere;
  filter: InputMaybe<ImageLocaleWhere>;
};


export type QueryTransactionGetImageWithTextArgs = {
  by: ImageWithTextUniqueWhere;
  filter: InputMaybe<ImageWithTextWhere>;
};


export type QueryTransactionGetLinkArgs = {
  by: LinkUniqueWhere;
  filter: InputMaybe<LinkWhere>;
};


export type QueryTransactionGetLocaleArgs = {
  by: LocaleUniqueWhere;
  filter: InputMaybe<LocaleWhere>;
};


export type QueryTransactionGetMetricArgs = {
  by: MetricUniqueWhere;
  filter: InputMaybe<MetricWhere>;
};


export type QueryTransactionGetQuotationArgs = {
  by: QuotationUniqueWhere;
  filter: InputMaybe<QuotationWhere>;
};


export type QueryTransactionGetQuotationWithImageArgs = {
  by: QuotationWithImageUniqueWhere;
  filter: InputMaybe<QuotationWithImageWhere>;
};


export type QueryTransactionGetSettingArgs = {
  by: SettingUniqueWhere;
  filter: InputMaybe<SettingWhere>;
};


export type QueryTransactionGetSettingItemArgs = {
  by: SettingItemUniqueWhere;
  filter: InputMaybe<SettingItemWhere>;
};


export type QueryTransactionGetStatusArgs = {
  by: StatusUniqueWhere;
  filter: InputMaybe<StatusWhere>;
};


export type QueryTransactionGetStoryArgs = {
  by: StoryUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type QueryTransactionGetStoryPartialScoreArgs = {
  by: StoryPartialScoreUniqueWhere;
  filter: InputMaybe<StoryPartialScoreWhere>;
};


export type QueryTransactionGetStoryScoreArgs = {
  by: StoryScoreUniqueWhere;
  filter: InputMaybe<StoryScoreWhere>;
};


export type QueryTransactionGetTagArgs = {
  by: TagUniqueWhere;
  filter: InputMaybe<TagWhere>;
};


export type QueryTransactionGetVideoArgs = {
  by: VideoUniqueWhere;
  filter: InputMaybe<VideoWhere>;
};


export type QueryTransactionGetVideoWithTextArgs = {
  by: VideoWithTextUniqueWhere;
  filter: InputMaybe<VideoWithTextWhere>;
};


export type QueryTransactionListClientArgs = {
  filter: InputMaybe<ClientWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ClientOrderBy>>;
};


export type QueryTransactionListContentArgs = {
  filter: InputMaybe<ContentWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentOrderBy>>;
};


export type QueryTransactionListContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
};


export type QueryTransactionListContentPartArgs = {
  filter: InputMaybe<ContentPartWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
};


export type QueryTransactionListContentReferenceArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
};


export type QueryTransactionListCreditArgs = {
  filter: InputMaybe<CreditWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
};


export type QueryTransactionListCreditRoleArgs = {
  filter: InputMaybe<CreditRoleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditRoleOrderBy>>;
};


export type QueryTransactionListFileArgs = {
  filter: InputMaybe<FileWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<FileOrderBy>>;
};


export type QueryTransactionListImageArgs = {
  filter: InputMaybe<ImageWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageOrderBy>>;
};


export type QueryTransactionListImageLocaleArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
};


export type QueryTransactionListImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageWithTextOrderBy>>;
};


export type QueryTransactionListLinkArgs = {
  filter: InputMaybe<LinkWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
};


export type QueryTransactionListLocaleArgs = {
  filter: InputMaybe<LocaleWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LocaleOrderBy>>;
};


export type QueryTransactionListMetricArgs = {
  filter: InputMaybe<MetricWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
};


export type QueryTransactionListQuotationArgs = {
  filter: InputMaybe<QuotationWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationOrderBy>>;
};


export type QueryTransactionListQuotationWithImageArgs = {
  filter: InputMaybe<QuotationWithImageWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationWithImageOrderBy>>;
};


export type QueryTransactionListSettingArgs = {
  filter: InputMaybe<SettingWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingOrderBy>>;
};


export type QueryTransactionListSettingItemArgs = {
  filter: InputMaybe<SettingItemWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
};


export type QueryTransactionListStatusArgs = {
  filter: InputMaybe<StatusWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StatusOrderBy>>;
};


export type QueryTransactionListStoryArgs = {
  filter: InputMaybe<StoryWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
};


export type QueryTransactionListStoryPartialScoreArgs = {
  filter: InputMaybe<StoryPartialScoreWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryPartialScoreOrderBy>>;
};


export type QueryTransactionListStoryScoreArgs = {
  filter: InputMaybe<StoryScoreWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryScoreOrderBy>>;
};


export type QueryTransactionListTagArgs = {
  filter: InputMaybe<TagWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
};


export type QueryTransactionListVideoArgs = {
  filter: InputMaybe<VideoWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoOrderBy>>;
};


export type QueryTransactionListVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoWithTextOrderBy>>;
};


export type QueryTransactionPaginateClientArgs = {
  filter: InputMaybe<ClientWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ClientOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateContentArgs = {
  filter: InputMaybe<ContentWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateContentPartArgs = {
  filter: InputMaybe<ContentPartWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentPartOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateContentReferenceArgs = {
  filter: InputMaybe<ContentReferenceWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentReferenceOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateCreditArgs = {
  filter: InputMaybe<CreditWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateCreditRoleArgs = {
  filter: InputMaybe<CreditRoleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditRoleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateFileArgs = {
  filter: InputMaybe<FileWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<FileOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateImageArgs = {
  filter: InputMaybe<ImageWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateImageLocaleArgs = {
  filter: InputMaybe<ImageLocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageLocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateImageWithTextArgs = {
  filter: InputMaybe<ImageWithTextWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ImageWithTextOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateLinkArgs = {
  filter: InputMaybe<LinkWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LinkOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateLocaleArgs = {
  filter: InputMaybe<LocaleWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<LocaleOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateMetricArgs = {
  filter: InputMaybe<MetricWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateQuotationArgs = {
  filter: InputMaybe<QuotationWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateQuotationWithImageArgs = {
  filter: InputMaybe<QuotationWithImageWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<QuotationWithImageOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateSettingArgs = {
  filter: InputMaybe<SettingWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateSettingItemArgs = {
  filter: InputMaybe<SettingItemWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateStatusArgs = {
  filter: InputMaybe<StatusWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StatusOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateStoryArgs = {
  filter: InputMaybe<StoryWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateStoryPartialScoreArgs = {
  filter: InputMaybe<StoryPartialScoreWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryPartialScoreOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateStoryScoreArgs = {
  filter: InputMaybe<StoryScoreWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryScoreOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateTagArgs = {
  filter: InputMaybe<TagWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateVideoArgs = {
  filter: InputMaybe<VideoWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionPaginateVideoWithTextArgs = {
  filter: InputMaybe<VideoWithTextWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<VideoWithTextOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionValidateCreateVoteArgs = {
  data: VoteCreateInput;
};

export type Quotation = {
  __typename?: 'Quotation';
  _meta: Maybe<QuotationMeta>;
  author: Scalars['String'];
  contentBlock: Maybe<ContentBlock>;
  id: Scalars['UUID'];
  quote: Scalars['String'];
  relationship: Maybe<Scalars['String']>;
};


export type QuotationContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
};

export type QuotationConnection = {
  __typename?: 'QuotationConnection';
  edges: Array<QuotationEdge>;
  pageInfo: PageInfo;
};

export type QuotationEdge = {
  __typename?: 'QuotationEdge';
  node: Quotation;
};

export type QuotationMeta = {
  __typename?: 'QuotationMeta';
  author: Maybe<FieldMeta>;
  contentBlock: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  quote: Maybe<FieldMeta>;
  relationship: Maybe<FieldMeta>;
};

export type QuotationOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  author: InputMaybe<OrderDirection>;
  contentBlock: InputMaybe<ContentBlockOrderBy>;
  id: InputMaybe<OrderDirection>;
  quote: InputMaybe<OrderDirection>;
  relationship: InputMaybe<OrderDirection>;
};

export type QuotationUniqueWhere = {
  contentBlock: InputMaybe<ContentBlockUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
};

export type QuotationWhere = {
  and: InputMaybe<Array<InputMaybe<QuotationWhere>>>;
  author: InputMaybe<StringCondition>;
  contentBlock: InputMaybe<ContentBlockWhere>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<QuotationWhere>;
  or: InputMaybe<Array<InputMaybe<QuotationWhere>>>;
  quote: InputMaybe<StringCondition>;
  relationship: InputMaybe<StringCondition>;
};

export type QuotationWithImage = {
  __typename?: 'QuotationWithImage';
  _meta: Maybe<QuotationWithImageMeta>;
  contentBlock: Maybe<ContentBlock>;
  id: Scalars['UUID'];
  image: Maybe<Image>;
  quote: Scalars['String'];
};


export type QuotationWithImageContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
};


export type QuotationWithImageImageArgs = {
  filter: InputMaybe<ImageWhere>;
};

export type QuotationWithImageConnection = {
  __typename?: 'QuotationWithImageConnection';
  edges: Array<QuotationWithImageEdge>;
  pageInfo: PageInfo;
};

export type QuotationWithImageEdge = {
  __typename?: 'QuotationWithImageEdge';
  node: QuotationWithImage;
};

export type QuotationWithImageMeta = {
  __typename?: 'QuotationWithImageMeta';
  contentBlock: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  image: Maybe<FieldMeta>;
  quote: Maybe<FieldMeta>;
};

export type QuotationWithImageOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  contentBlock: InputMaybe<ContentBlockOrderBy>;
  id: InputMaybe<OrderDirection>;
  image: InputMaybe<ImageOrderBy>;
  quote: InputMaybe<OrderDirection>;
};

export type QuotationWithImageUniqueWhere = {
  contentBlock: InputMaybe<ContentBlockUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
};

export type QuotationWithImageWhere = {
  and: InputMaybe<Array<InputMaybe<QuotationWithImageWhere>>>;
  contentBlock: InputMaybe<ContentBlockWhere>;
  id: InputMaybe<UuidCondition>;
  image: InputMaybe<ImageWhere>;
  not: InputMaybe<QuotationWithImageWhere>;
  or: InputMaybe<Array<InputMaybe<QuotationWithImageWhere>>>;
  quote: InputMaybe<StringCondition>;
};

export type S3Header = {
  __typename?: 'S3Header';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type S3SignedRead = {
  __typename?: 'S3SignedRead';
  bucket: Scalars['String'];
  headers: Array<S3Header>;
  method: Scalars['String'];
  /**
   * Allowed patterns:
   * **
   */
  objectKey: Scalars['String'];
  url: Scalars['String'];
};

export type S3SignedUpload = {
  __typename?: 'S3SignedUpload';
  bucket: Scalars['String'];
  headers: Array<S3Header>;
  method: Scalars['String'];
  /** Allowed patterns: */
  objectKey: Scalars['String'];
  publicUrl: Scalars['String'];
  url: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  _meta: Maybe<SettingMeta>;
  id: Scalars['UUID'];
  items: Array<SettingItem>;
  itemsByKey: Maybe<SettingItem>;
  paginateItems: SettingItemConnection;
  unique: SettingUnique;
};


export type SettingItemsArgs = {
  filter: InputMaybe<SettingItemWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
};


export type SettingItemsByKeyArgs = {
  by: SettingItemsByKeyUniqueWhere;
  filter: InputMaybe<SettingItemWhere>;
};


export type SettingPaginateItemsArgs = {
  filter: InputMaybe<SettingItemWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<SettingItemOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SettingConnection = {
  __typename?: 'SettingConnection';
  edges: Array<SettingEdge>;
  pageInfo: PageInfo;
};

export type SettingEdge = {
  __typename?: 'SettingEdge';
  node: Setting;
};

export type SettingItem = {
  __typename?: 'SettingItem';
  _meta: Maybe<SettingItemMeta>;
  base: Maybe<Setting>;
  id: Scalars['UUID'];
  key: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['String'];
};


export type SettingItemBaseArgs = {
  filter: InputMaybe<SettingWhere>;
};

export type SettingItemConnection = {
  __typename?: 'SettingItemConnection';
  edges: Array<SettingItemEdge>;
  pageInfo: PageInfo;
};

export type SettingItemEdge = {
  __typename?: 'SettingItemEdge';
  node: SettingItem;
};

export type SettingItemMeta = {
  __typename?: 'SettingItemMeta';
  base: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  key: Maybe<FieldMeta>;
  order: Maybe<FieldMeta>;
  value: Maybe<FieldMeta>;
};

export type SettingItemOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  base: InputMaybe<SettingOrderBy>;
  id: InputMaybe<OrderDirection>;
  key: InputMaybe<OrderDirection>;
  order: InputMaybe<OrderDirection>;
  value: InputMaybe<OrderDirection>;
};

export type SettingItemUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  key: InputMaybe<Scalars['String']>;
};

export type SettingItemWhere = {
  and: InputMaybe<Array<InputMaybe<SettingItemWhere>>>;
  base: InputMaybe<SettingWhere>;
  id: InputMaybe<UuidCondition>;
  key: InputMaybe<StringCondition>;
  not: InputMaybe<SettingItemWhere>;
  or: InputMaybe<Array<InputMaybe<SettingItemWhere>>>;
  order: InputMaybe<IntCondition>;
  value: InputMaybe<StringCondition>;
};

export type SettingItemsByKeyUniqueWhere = {
  key: InputMaybe<Scalars['String']>;
};

export type SettingMeta = {
  __typename?: 'SettingMeta';
  id: Maybe<FieldMeta>;
  items: Maybe<FieldMeta>;
  unique: Maybe<FieldMeta>;
};

export type SettingOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  unique: InputMaybe<OrderDirection>;
};

export enum SettingUnique {
  One = 'One'
}

export type SettingUniqueCondition = {
  and: InputMaybe<Array<SettingUniqueCondition>>;
  eq: InputMaybe<SettingUnique>;
  gt: InputMaybe<SettingUnique>;
  gte: InputMaybe<SettingUnique>;
  in: InputMaybe<Array<SettingUnique>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<SettingUnique>;
  lte: InputMaybe<SettingUnique>;
  not: InputMaybe<SettingUniqueCondition>;
  notEq: InputMaybe<SettingUnique>;
  notIn: InputMaybe<Array<SettingUnique>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<SettingUniqueCondition>>;
};

export type SettingUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  items: InputMaybe<SettingItemUniqueWhere>;
  unique: InputMaybe<SettingUnique>;
};

export type SettingWhere = {
  and: InputMaybe<Array<InputMaybe<SettingWhere>>>;
  id: InputMaybe<UuidCondition>;
  items: InputMaybe<SettingItemWhere>;
  not: InputMaybe<SettingWhere>;
  or: InputMaybe<Array<InputMaybe<SettingWhere>>>;
  unique: InputMaybe<SettingUniqueCondition>;
};

export type Status = {
  __typename?: 'Status';
  _meta: Maybe<StatusMeta>;
  id: Scalars['UUID'];
  label: Scalars['String'];
  paginateStories: StoryConnection;
  stories: Array<Story>;
  storiesByBlocks: Maybe<Story>;
  storiesByCredits: Maybe<Story>;
  storiesByMetrics: Maybe<Story>;
  storiesByPartialScore: Maybe<Story>;
  storiesByScore: Maybe<Story>;
};


export type StatusPaginateStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type StatusStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
};


export type StatusStoriesByBlocksArgs = {
  by: StatusStoriesByBlocksUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type StatusStoriesByCreditsArgs = {
  by: StatusStoriesByCreditsUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type StatusStoriesByMetricsArgs = {
  by: StatusStoriesByMetricsUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type StatusStoriesByPartialScoreArgs = {
  by: StatusStoriesByPartialScoreUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};


export type StatusStoriesByScoreArgs = {
  by: StatusStoriesByScoreUniqueWhere;
  filter: InputMaybe<StoryWhere>;
};

export type StatusConnection = {
  __typename?: 'StatusConnection';
  edges: Array<StatusEdge>;
  pageInfo: PageInfo;
};

export type StatusEdge = {
  __typename?: 'StatusEdge';
  node: Status;
};

export type StatusMeta = {
  __typename?: 'StatusMeta';
  id: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
  stories: Maybe<FieldMeta>;
};

export type StatusOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
};

export type StatusStoriesByBlocksUniqueWhere = {
  blocks: InputMaybe<ContentBlockUniqueWhere>;
};

export type StatusStoriesByCreditsUniqueWhere = {
  credits: InputMaybe<CreditUniqueWhere>;
};

export type StatusStoriesByMetricsUniqueWhere = {
  metrics: InputMaybe<MetricUniqueWhere>;
};

export type StatusStoriesByPartialScoreUniqueWhere = {
  partialScore: InputMaybe<StoryPartialScoreUniqueWhere>;
};

export type StatusStoriesByScoreUniqueWhere = {
  score: InputMaybe<StoryScoreUniqueWhere>;
};

export type StatusUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  label: InputMaybe<Scalars['String']>;
  stories: InputMaybe<StoryUniqueWhere>;
};

export type StatusWhere = {
  and: InputMaybe<Array<InputMaybe<StatusWhere>>>;
  id: InputMaybe<UuidCondition>;
  label: InputMaybe<StringCondition>;
  not: InputMaybe<StatusWhere>;
  or: InputMaybe<Array<InputMaybe<StatusWhere>>>;
  stories: InputMaybe<StoryWhere>;
};

export type Story = {
  __typename?: 'Story';
  _meta: Maybe<StoryMeta>;
  blocks: Array<ContentBlock>;
  blocksByContent: Maybe<ContentBlock>;
  blocksByImageWithText: Maybe<ContentBlock>;
  blocksByLink: Maybe<ContentBlock>;
  blocksByQuotation: Maybe<ContentBlock>;
  blocksByQuotationWithImage: Maybe<ContentBlock>;
  blocksByVideoWithText: Maybe<ContentBlock>;
  caseStudy: Maybe<Video>;
  caseStudyLength: Maybe<Scalars['String']>;
  caseStudyPreview: Maybe<Image>;
  client: Maybe<Client>;
  createdAt: Scalars['DateTime'];
  credits: Array<Credit>;
  date: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  headline: Scalars['String'];
  id: Scalars['UUID'];
  metrics: Array<Metric>;
  notificationSentAt: Maybe<Scalars['DateTime']>;
  paginateBlocks: ContentBlockConnection;
  paginateCredits: CreditConnection;
  paginateMetrics: MetricConnection;
  paginateTags: TagConnection;
  partialScore: Maybe<StoryPartialScore>;
  primaryImage: Maybe<Image>;
  primaryVideo: Maybe<Video>;
  projectName: Scalars['String'];
  publishAt: Maybe<Scalars['DateTime']>;
  result: Maybe<Scalars['String']>;
  score: Maybe<StoryScore>;
  status: Maybe<Status>;
  tags: Array<Tag>;
  thumbnail: Maybe<Image>;
  wallpaper: Maybe<Image>;
};


export type StoryBlocksArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
};


export type StoryBlocksByContentArgs = {
  by: StoryBlocksByContentUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryBlocksByImageWithTextArgs = {
  by: StoryBlocksByImageWithTextUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryBlocksByLinkArgs = {
  by: StoryBlocksByLinkUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryBlocksByQuotationArgs = {
  by: StoryBlocksByQuotationUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryBlocksByQuotationWithImageArgs = {
  by: StoryBlocksByQuotationWithImageUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryBlocksByVideoWithTextArgs = {
  by: StoryBlocksByVideoWithTextUniqueWhere;
  filter: InputMaybe<ContentBlockWhere>;
};


export type StoryCaseStudyArgs = {
  filter: InputMaybe<VideoWhere>;
};


export type StoryCaseStudyPreviewArgs = {
  filter: InputMaybe<ImageWhere>;
};


export type StoryClientArgs = {
  filter: InputMaybe<ClientWhere>;
};


export type StoryCreditsArgs = {
  filter: InputMaybe<CreditWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
};


export type StoryMetricsArgs = {
  filter: InputMaybe<MetricWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
};


export type StoryPaginateBlocksArgs = {
  filter: InputMaybe<ContentBlockWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<ContentBlockOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type StoryPaginateCreditsArgs = {
  filter: InputMaybe<CreditWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<CreditOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type StoryPaginateMetricsArgs = {
  filter: InputMaybe<MetricWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<MetricOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type StoryPaginateTagsArgs = {
  filter: InputMaybe<TagWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type StoryPartialScoreArgs = {
  filter: InputMaybe<StoryPartialScoreWhere>;
};


export type StoryPrimaryImageArgs = {
  filter: InputMaybe<ImageWhere>;
};


export type StoryPrimaryVideoArgs = {
  filter: InputMaybe<VideoWhere>;
};


export type StoryScoreArgs = {
  filter: InputMaybe<StoryScoreWhere>;
};


export type StoryStatusArgs = {
  filter: InputMaybe<StatusWhere>;
};


export type StoryTagsArgs = {
  filter: InputMaybe<TagWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<TagOrderBy>>;
};


export type StoryThumbnailArgs = {
  filter: InputMaybe<ImageWhere>;
};


export type StoryWallpaperArgs = {
  filter: InputMaybe<ImageWhere>;
};

export type StoryBlocksByContentUniqueWhere = {
  content: InputMaybe<ContentUniqueWhere>;
};

export type StoryBlocksByImageWithTextUniqueWhere = {
  imageWithText: InputMaybe<ImageWithTextUniqueWhere>;
};

export type StoryBlocksByLinkUniqueWhere = {
  link: InputMaybe<LinkUniqueWhere>;
};

export type StoryBlocksByQuotationUniqueWhere = {
  quotation: InputMaybe<QuotationUniqueWhere>;
};

export type StoryBlocksByQuotationWithImageUniqueWhere = {
  quotationWithImage: InputMaybe<QuotationWithImageUniqueWhere>;
};

export type StoryBlocksByVideoWithTextUniqueWhere = {
  videoWithText: InputMaybe<VideoWithTextUniqueWhere>;
};

export type StoryConnection = {
  __typename?: 'StoryConnection';
  edges: Array<StoryEdge>;
  pageInfo: PageInfo;
};

export type StoryEdge = {
  __typename?: 'StoryEdge';
  node: Story;
};

export type StoryMeta = {
  __typename?: 'StoryMeta';
  blocks: Maybe<FieldMeta>;
  caseStudy: Maybe<FieldMeta>;
  caseStudyLength: Maybe<FieldMeta>;
  caseStudyPreview: Maybe<FieldMeta>;
  client: Maybe<FieldMeta>;
  createdAt: Maybe<FieldMeta>;
  credits: Maybe<FieldMeta>;
  date: Maybe<FieldMeta>;
  description: Maybe<FieldMeta>;
  headline: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  metrics: Maybe<FieldMeta>;
  notificationSentAt: Maybe<FieldMeta>;
  partialScore: Maybe<FieldMeta>;
  primaryImage: Maybe<FieldMeta>;
  primaryVideo: Maybe<FieldMeta>;
  projectName: Maybe<FieldMeta>;
  publishAt: Maybe<FieldMeta>;
  result: Maybe<FieldMeta>;
  score: Maybe<FieldMeta>;
  status: Maybe<FieldMeta>;
  tags: Maybe<FieldMeta>;
  thumbnail: Maybe<FieldMeta>;
  wallpaper: Maybe<FieldMeta>;
};

export type StoryOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  caseStudy: InputMaybe<VideoOrderBy>;
  caseStudyLength: InputMaybe<OrderDirection>;
  caseStudyPreview: InputMaybe<ImageOrderBy>;
  client: InputMaybe<ClientOrderBy>;
  createdAt: InputMaybe<OrderDirection>;
  date: InputMaybe<OrderDirection>;
  description: InputMaybe<OrderDirection>;
  headline: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  notificationSentAt: InputMaybe<OrderDirection>;
  partialScore: InputMaybe<StoryPartialScoreOrderBy>;
  primaryImage: InputMaybe<ImageOrderBy>;
  primaryVideo: InputMaybe<VideoOrderBy>;
  projectName: InputMaybe<OrderDirection>;
  publishAt: InputMaybe<OrderDirection>;
  result: InputMaybe<OrderDirection>;
  score: InputMaybe<StoryScoreOrderBy>;
  status: InputMaybe<StatusOrderBy>;
  thumbnail: InputMaybe<ImageOrderBy>;
  wallpaper: InputMaybe<ImageOrderBy>;
};

export type StoryPartialScore = {
  __typename?: 'StoryPartialScore';
  _meta: Maybe<StoryPartialScoreMeta>;
  average: Scalars['Float'];
  count: Scalars['Int'];
  id: Scalars['UUID'];
  story: Maybe<Story>;
  type: Maybe<VoteTypeEnum>;
};


export type StoryPartialScoreStoryArgs = {
  filter: InputMaybe<StoryWhere>;
};

export type StoryPartialScoreConnection = {
  __typename?: 'StoryPartialScoreConnection';
  edges: Array<StoryPartialScoreEdge>;
  pageInfo: PageInfo;
};

export type StoryPartialScoreEdge = {
  __typename?: 'StoryPartialScoreEdge';
  node: StoryPartialScore;
};

export type StoryPartialScoreMeta = {
  __typename?: 'StoryPartialScoreMeta';
  average: Maybe<FieldMeta>;
  count: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  story: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
};

export type StoryPartialScoreOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  average: InputMaybe<OrderDirection>;
  count: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  story: InputMaybe<StoryOrderBy>;
  type: InputMaybe<OrderDirection>;
};

export type StoryPartialScoreUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  story: InputMaybe<StoryUniqueWhere>;
};

export type StoryPartialScoreWhere = {
  and: InputMaybe<Array<InputMaybe<StoryPartialScoreWhere>>>;
  average: InputMaybe<FloatCondition>;
  count: InputMaybe<IntCondition>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<StoryPartialScoreWhere>;
  or: InputMaybe<Array<InputMaybe<StoryPartialScoreWhere>>>;
  story: InputMaybe<StoryWhere>;
  type: InputMaybe<VoteTypeEnumCondition>;
};

export type StoryScore = {
  __typename?: 'StoryScore';
  _meta: Maybe<StoryScoreMeta>;
  average: Scalars['Float'];
  count: Scalars['Int'];
  id: Scalars['UUID'];
  story: Maybe<Story>;
};


export type StoryScoreStoryArgs = {
  filter: InputMaybe<StoryWhere>;
};

export type StoryScoreConnection = {
  __typename?: 'StoryScoreConnection';
  edges: Array<StoryScoreEdge>;
  pageInfo: PageInfo;
};

export type StoryScoreEdge = {
  __typename?: 'StoryScoreEdge';
  node: StoryScore;
};

export type StoryScoreMeta = {
  __typename?: 'StoryScoreMeta';
  average: Maybe<FieldMeta>;
  count: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  story: Maybe<FieldMeta>;
};

export type StoryScoreOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  average: InputMaybe<OrderDirection>;
  count: InputMaybe<OrderDirection>;
  id: InputMaybe<OrderDirection>;
  story: InputMaybe<StoryOrderBy>;
};

export type StoryScoreUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  story: InputMaybe<StoryUniqueWhere>;
};

export type StoryScoreWhere = {
  and: InputMaybe<Array<InputMaybe<StoryScoreWhere>>>;
  average: InputMaybe<FloatCondition>;
  count: InputMaybe<IntCondition>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<StoryScoreWhere>;
  or: InputMaybe<Array<InputMaybe<StoryScoreWhere>>>;
  story: InputMaybe<StoryWhere>;
};

export type StoryUniqueWhere = {
  blocks: InputMaybe<ContentBlockUniqueWhere>;
  credits: InputMaybe<CreditUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
  metrics: InputMaybe<MetricUniqueWhere>;
  partialScore: InputMaybe<StoryPartialScoreUniqueWhere>;
  score: InputMaybe<StoryScoreUniqueWhere>;
};

export type StoryWhere = {
  and: InputMaybe<Array<InputMaybe<StoryWhere>>>;
  blocks: InputMaybe<ContentBlockWhere>;
  caseStudy: InputMaybe<VideoWhere>;
  caseStudyLength: InputMaybe<StringCondition>;
  caseStudyPreview: InputMaybe<ImageWhere>;
  client: InputMaybe<ClientWhere>;
  createdAt: InputMaybe<DateTimeCondition>;
  credits: InputMaybe<CreditWhere>;
  date: InputMaybe<DateCondition>;
  description: InputMaybe<StringCondition>;
  headline: InputMaybe<StringCondition>;
  id: InputMaybe<UuidCondition>;
  metrics: InputMaybe<MetricWhere>;
  not: InputMaybe<StoryWhere>;
  notificationSentAt: InputMaybe<DateTimeCondition>;
  or: InputMaybe<Array<InputMaybe<StoryWhere>>>;
  partialScore: InputMaybe<StoryPartialScoreWhere>;
  primaryImage: InputMaybe<ImageWhere>;
  primaryVideo: InputMaybe<VideoWhere>;
  projectName: InputMaybe<StringCondition>;
  publishAt: InputMaybe<DateTimeCondition>;
  result: InputMaybe<StringCondition>;
  score: InputMaybe<StoryScoreWhere>;
  status: InputMaybe<StatusWhere>;
  tags: InputMaybe<TagWhere>;
  thumbnail: InputMaybe<ImageWhere>;
  wallpaper: InputMaybe<ImageWhere>;
};

export type StringCondition = {
  and: InputMaybe<Array<StringCondition>>;
  contains: InputMaybe<Scalars['String']>;
  containsCI: InputMaybe<Scalars['String']>;
  endsWith: InputMaybe<Scalars['String']>;
  endsWithCI: InputMaybe<Scalars['String']>;
  eq: InputMaybe<Scalars['String']>;
  gt: InputMaybe<Scalars['String']>;
  gte: InputMaybe<Scalars['String']>;
  in: InputMaybe<Array<Scalars['String']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['String']>;
  lte: InputMaybe<Scalars['String']>;
  not: InputMaybe<StringCondition>;
  notEq: InputMaybe<Scalars['String']>;
  notIn: InputMaybe<Array<Scalars['String']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<StringCondition>>;
  startsWith: InputMaybe<Scalars['String']>;
  startsWithCI: InputMaybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  _meta: Maybe<TagMeta>;
  id: Scalars['UUID'];
  label: Scalars['String'];
  paginateStories: StoryConnection;
  stories: Array<Story>;
};


export type TagPaginateStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  first: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
  skip: InputMaybe<Scalars['Int']>;
};


export type TagStoriesArgs = {
  filter: InputMaybe<StoryWhere>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Array<StoryOrderBy>>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges: Array<TagEdge>;
  pageInfo: PageInfo;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  node: Tag;
};

export type TagMeta = {
  __typename?: 'TagMeta';
  id: Maybe<FieldMeta>;
  label: Maybe<FieldMeta>;
  stories: Maybe<FieldMeta>;
};

export type TagOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  label: InputMaybe<OrderDirection>;
};

export type TagUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
  label: InputMaybe<Scalars['String']>;
};

export type TagWhere = {
  and: InputMaybe<Array<InputMaybe<TagWhere>>>;
  id: InputMaybe<UuidCondition>;
  label: InputMaybe<StringCondition>;
  not: InputMaybe<TagWhere>;
  or: InputMaybe<Array<InputMaybe<TagWhere>>>;
  stories: InputMaybe<StoryWhere>;
};

export type UuidCondition = {
  and: InputMaybe<Array<UuidCondition>>;
  eq: InputMaybe<Scalars['UUID']>;
  gt: InputMaybe<Scalars['UUID']>;
  gte: InputMaybe<Scalars['UUID']>;
  in: InputMaybe<Array<Scalars['UUID']>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<Scalars['UUID']>;
  lte: InputMaybe<Scalars['UUID']>;
  not: InputMaybe<UuidCondition>;
  notEq: InputMaybe<Scalars['UUID']>;
  notIn: InputMaybe<Array<Scalars['UUID']>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<UuidCondition>>;
};

export type Video = {
  __typename?: 'Video';
  _meta: Maybe<VideoMeta>;
  id: Scalars['UUID'];
  size: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type VideoConnection = {
  __typename?: 'VideoConnection';
  edges: Array<VideoEdge>;
  pageInfo: PageInfo;
};

export type VideoEdge = {
  __typename?: 'VideoEdge';
  node: Video;
};

export type VideoMeta = {
  __typename?: 'VideoMeta';
  id: Maybe<FieldMeta>;
  size: Maybe<FieldMeta>;
  type: Maybe<FieldMeta>;
  url: Maybe<FieldMeta>;
};

export type VideoOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  id: InputMaybe<OrderDirection>;
  size: InputMaybe<OrderDirection>;
  type: InputMaybe<OrderDirection>;
  url: InputMaybe<OrderDirection>;
};

export type VideoUniqueWhere = {
  id: InputMaybe<Scalars['UUID']>;
};

export type VideoWhere = {
  and: InputMaybe<Array<InputMaybe<VideoWhere>>>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<VideoWhere>;
  or: InputMaybe<Array<InputMaybe<VideoWhere>>>;
  size: InputMaybe<IntCondition>;
  type: InputMaybe<StringCondition>;
  url: InputMaybe<StringCondition>;
};

export type VideoWithText = {
  __typename?: 'VideoWithText';
  _meta: Maybe<VideoWithTextMeta>;
  content: Maybe<Content>;
  contentBlock: Maybe<ContentBlock>;
  id: Scalars['UUID'];
  video: Maybe<Video>;
};


export type VideoWithTextContentArgs = {
  filter: InputMaybe<ContentWhere>;
};


export type VideoWithTextContentBlockArgs = {
  filter: InputMaybe<ContentBlockWhere>;
};


export type VideoWithTextVideoArgs = {
  filter: InputMaybe<VideoWhere>;
};

export type VideoWithTextConnection = {
  __typename?: 'VideoWithTextConnection';
  edges: Array<VideoWithTextEdge>;
  pageInfo: PageInfo;
};

export type VideoWithTextEdge = {
  __typename?: 'VideoWithTextEdge';
  node: VideoWithText;
};

export type VideoWithTextMeta = {
  __typename?: 'VideoWithTextMeta';
  content: Maybe<FieldMeta>;
  contentBlock: Maybe<FieldMeta>;
  id: Maybe<FieldMeta>;
  video: Maybe<FieldMeta>;
};

export type VideoWithTextOrderBy = {
  _random: InputMaybe<Scalars['Boolean']>;
  _randomSeeded: InputMaybe<Scalars['Int']>;
  content: InputMaybe<ContentOrderBy>;
  contentBlock: InputMaybe<ContentBlockOrderBy>;
  id: InputMaybe<OrderDirection>;
  video: InputMaybe<VideoOrderBy>;
};

export type VideoWithTextUniqueWhere = {
  content: InputMaybe<ContentUniqueWhere>;
  contentBlock: InputMaybe<ContentBlockUniqueWhere>;
  id: InputMaybe<Scalars['UUID']>;
};

export type VideoWithTextWhere = {
  and: InputMaybe<Array<InputMaybe<VideoWithTextWhere>>>;
  content: InputMaybe<ContentWhere>;
  contentBlock: InputMaybe<ContentBlockWhere>;
  id: InputMaybe<UuidCondition>;
  not: InputMaybe<VideoWithTextWhere>;
  or: InputMaybe<Array<InputMaybe<VideoWithTextWhere>>>;
  video: InputMaybe<VideoWhere>;
};

export type VoteCreateInput = {
  _dummy_field_: InputMaybe<Scalars['Boolean']>;
  createdAt: InputMaybe<Scalars['DateTime']>;
  score: InputMaybe<Scalars['Int']>;
  story: InputMaybe<VoteCreateStoryEntityRelationInput>;
  type: InputMaybe<VoteTypeEnum>;
};

export type VoteCreateResult = MutationResult & {
  __typename?: 'VoteCreateResult';
  errorMessage: Maybe<Scalars['String']>;
  errors: Array<_MutationError>;
  ok: Scalars['Boolean'];
  validation: _ValidationResult;
};

export type VoteCreateStoryEntityRelationInput = {
  connect: InputMaybe<StoryUniqueWhere>;
};

export enum VoteTypeEnum {
  Craft = 'craft',
  Distinctiveness = 'distinctiveness',
  Insight = 'insight',
  Salience = 'salience',
  Storytelling = 'storytelling',
  Targeting = 'targeting'
}

export type VoteTypeEnumCondition = {
  and: InputMaybe<Array<VoteTypeEnumCondition>>;
  eq: InputMaybe<VoteTypeEnum>;
  gt: InputMaybe<VoteTypeEnum>;
  gte: InputMaybe<VoteTypeEnum>;
  in: InputMaybe<Array<VoteTypeEnum>>;
  isNull: InputMaybe<Scalars['Boolean']>;
  lt: InputMaybe<VoteTypeEnum>;
  lte: InputMaybe<VoteTypeEnum>;
  not: InputMaybe<VoteTypeEnumCondition>;
  notEq: InputMaybe<VoteTypeEnum>;
  notIn: InputMaybe<Array<VoteTypeEnum>>;
  null: InputMaybe<Scalars['Boolean']>;
  or: InputMaybe<Array<VoteTypeEnumCondition>>;
};

export type _Argument = _LiteralArgument | _PathArgument | _ValidatorArgument;

export type _Column = _Field & {
  __typename?: '_Column';
  defaultValue: Maybe<Scalars['Json']>;
  enumName: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nullable: Scalars['Boolean'];
  rules: Array<_Rule>;
  type: Scalars['String'];
  validators: Array<_Validator>;
};

export type _Entity = {
  __typename?: '_Entity';
  customPrimaryAllowed: Scalars['Boolean'];
  fields: Array<_Field>;
  name: Scalars['String'];
  unique: Array<_UniqueConstraint>;
};

export type _Enum = {
  __typename?: '_Enum';
  name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type _Field = {
  name: Scalars['String'];
  nullable: Maybe<Scalars['Boolean']>;
  rules: Array<_Rule>;
  type: Scalars['String'];
  validators: Array<_Validator>;
};

export type _FieldPathFragment = {
  __typename?: '_FieldPathFragment';
  field: Scalars['String'];
};

export type _IndexPathFragment = {
  __typename?: '_IndexPathFragment';
  alias: Maybe<Scalars['String']>;
  index: Scalars['Int'];
};

export type _LiteralArgument = {
  __typename?: '_LiteralArgument';
  value: Maybe<Scalars['Json']>;
};

export type _MutationError = {
  __typename?: '_MutationError';
  message: Maybe<Scalars['String']>;
  /** @deprecated Use `paths`. */
  path: Array<_PathFragment>;
  paths: Array<Array<_PathFragment>>;
  type: _MutationErrorType;
};

export enum _MutationErrorType {
  ForeignKeyConstraintViolation = 'ForeignKeyConstraintViolation',
  InvalidDataInput = 'InvalidDataInput',
  NonUniqueWhereInput = 'NonUniqueWhereInput',
  NotFoundOrDenied = 'NotFoundOrDenied',
  NotNullConstraintViolation = 'NotNullConstraintViolation',
  SqlError = 'SqlError',
  UniqueConstraintViolation = 'UniqueConstraintViolation'
}

export enum _OnDeleteBehaviour {
  Cascade = 'cascade',
  Restrict = 'restrict',
  SetNull = 'setNull'
}

export type _OrderBy = {
  __typename?: '_OrderBy';
  direction: _OrderByDirection;
  path: Array<Scalars['String']>;
};

export enum _OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type _PathArgument = {
  __typename?: '_PathArgument';
  path: Array<Scalars['String']>;
};

export type _PathFragment = _FieldPathFragment | _IndexPathFragment;

export type _Relation = _Field & {
  __typename?: '_Relation';
  inversedBy: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nullable: Maybe<Scalars['Boolean']>;
  onDelete: Maybe<_OnDeleteBehaviour>;
  orderBy: Maybe<Array<_OrderBy>>;
  orphanRemoval: Maybe<Scalars['Boolean']>;
  ownedBy: Maybe<Scalars['String']>;
  rules: Array<_Rule>;
  side: _RelationSide;
  targetEntity: Scalars['String'];
  type: Scalars['String'];
  validators: Array<_Validator>;
};

export enum _RelationSide {
  Inverse = 'inverse',
  Owning = 'owning'
}

export type _Rule = {
  __typename?: '_Rule';
  message: Maybe<_RuleMessage>;
  validator: Scalars['Int'];
};

export type _RuleMessage = {
  __typename?: '_RuleMessage';
  text: Maybe<Scalars['String']>;
};

export type _Schema = {
  __typename?: '_Schema';
  entities: Array<_Entity>;
  enums: Array<_Enum>;
};

export type _UniqueConstraint = {
  __typename?: '_UniqueConstraint';
  fields: Array<Scalars['String']>;
};

export type _ValidationError = {
  __typename?: '_ValidationError';
  message: _ValidationMessage;
  path: Array<_PathFragment>;
};

export type _ValidationMessage = {
  __typename?: '_ValidationMessage';
  text: Scalars['String'];
};

export type _ValidationResult = {
  __typename?: '_ValidationResult';
  errors: Array<_ValidationError>;
  valid: Scalars['Boolean'];
};

export type _Validator = {
  __typename?: '_Validator';
  arguments: Array<_Argument>;
  operation: Scalars['String'];
};

export type _ValidatorArgument = {
  __typename?: '_ValidatorArgument';
  validator: Scalars['Int'];
};

export type BlockFieldsFragment = { __typename?: 'ContentBlock', id: any, type: ContentBlockType, primaryText: string | null, alt: string | null, bgColor: ContentBlockBackground | null, imageWithText: { __typename?: 'ImageWithText', image: (
      { __typename?: 'Image' }
      & ImageFieldsFragment
    ) | null, content: (
      { __typename?: 'Content' }
      & ContentFieldsFragment
    ) | null } | null, videoWithText: { __typename?: 'VideoWithText', video: (
      { __typename?: 'Video' }
      & VideoFieldsFragment
    ) | null, content: (
      { __typename?: 'Content' }
      & ContentFieldsFragment
    ) | null } | null, quotation: { __typename?: 'Quotation', quote: string, author: string, relationship: string | null } | null, quotationWithImage: { __typename?: 'QuotationWithImage', quote: string, image: { __typename?: 'Image', url: string | null, type: string | null, size: number | null } | null } | null, content: (
    { __typename?: 'Content' }
    & ContentFieldsFragment
  ) | null, image: (
    { __typename?: 'Image' }
    & ImageFieldsFragment
  ) | null };

export type ImageFieldsFragment = { __typename?: 'Image', id: any, url: string | null, locales: Array<{ __typename?: 'ImageLocale', alt: string | null }> };

export type VideoFieldsFragment = { __typename?: 'Video', id: any, url: string | null };

export type ContentFieldsFragment = { __typename?: 'Content', id: any, parts: Array<{ __typename?: 'ContentPart', id: any, json: string, references: Array<{ __typename?: 'ContentReference', id: any, type: ContentReferenceType, target: (
        { __typename?: 'Link' }
        & LinkFieldsFragment
      ) | null }> }> };

export type LinkFieldsFragment = { __typename?: 'Link', url: string | null, type: LinkEnum, file: { __typename?: 'File', id: any, url: string, label: string } | null };

export type StoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type StoriesQuery = { __typename?: 'Query', stories: Array<{ __typename?: 'Story', id: any, date: any | null, projectName: string, score: { __typename?: 'StoryScore', count: number, average: number } | null, partialScore: { __typename?: 'StoryPartialScore', count: number, average: number, type: VoteTypeEnum | null } | null, client: { __typename?: 'Client', label: string } | null, thumbnail: { __typename?: 'Image', url: string | null, locales: Array<{ __typename?: 'ImageLocale', alt: string | null }> } | null, tags: Array<{ __typename?: 'Tag', label: string }> }> };

export type StoryByIdQueryVariables = Exact<{
  id: InputMaybe<Scalars['UUID']>;
}>;


export type StoryByIdQuery = { __typename?: 'Query', story: { __typename?: 'Story', id: any, date: any | null, description: string, result: string | null, projectName: string, caseStudyLength: string | null, score: { __typename?: 'StoryScore', count: number, average: number } | null, storytellingScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, craftScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, insightScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, distinctivenessScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, salienceScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, targetingScore: { __typename?: 'StoryPartialScore', count: number, average: number } | null, credits: Array<{ __typename?: 'Credit', name: string, role: { __typename?: 'CreditRole', label: string } | null }>, metrics: Array<{ __typename?: 'Metric', name: string, value: string }>, client: { __typename?: 'Client', label: string } | null, thumbnail: { __typename?: 'Image', url: string | null, locales: Array<{ __typename?: 'ImageLocale', alt: string | null }> } | null, tags: Array<{ __typename?: 'Tag', label: string }>, primaryImage: { __typename?: 'Image', url: string | null, type: string | null, size: number | null } | null, primaryVideo: { __typename?: 'Video', url: string | null, type: string | null, size: number | null } | null, caseStudy: { __typename?: 'Video', url: string | null, type: string | null, size: number | null } | null, caseStudyPreview: { __typename?: 'Image', url: string | null, type: string | null, size: number | null } | null, blocks: Array<(
      { __typename?: 'ContentBlock' }
      & BlockFieldsFragment
    )> } | null };

export type CreateVoteMutationVariables = Exact<{
  data: VoteCreateInput;
}>;


export type CreateVoteMutation = { __typename?: 'Mutation', createVote: { __typename?: 'VoteCreateResult', ok: boolean, errorMessage: string | null } };

export const ImageFieldsFragmentDoc = gql`
    fragment imageFields on Image {
  id
  url
  locales {
    alt
  }
}
    `;
export const LinkFieldsFragmentDoc = gql`
    fragment linkFields on Link {
  url
  type
  file {
    id
    url
    label
  }
}
    `;
export const ContentFieldsFragmentDoc = gql`
    fragment contentFields on Content {
  id
  parts {
    id
    json
    references {
      id
      type
      target {
        ...linkFields
      }
    }
  }
}
    ${LinkFieldsFragmentDoc}`;
export const VideoFieldsFragmentDoc = gql`
    fragment videoFields on Video {
  id
  url
}
    `;
export const BlockFieldsFragmentDoc = gql`
    fragment blockFields on ContentBlock {
  id
  type
  primaryText
  alt
  bgColor
  imageWithText {
    image {
      ...imageFields
    }
    content {
      ...contentFields
    }
  }
  videoWithText {
    video {
      ...videoFields
    }
    content {
      ...contentFields
    }
  }
  quotation {
    quote
    author
    relationship
  }
  quotationWithImage {
    quote
    image {
      url
      type
      size
    }
  }
  content {
    ...contentFields
  }
  image {
    ...imageFields
  }
}
    ${ImageFieldsFragmentDoc}
${ContentFieldsFragmentDoc}
${VideoFieldsFragmentDoc}`;
export const StoriesDocument = gql`
    query stories {
  stories: listStory(
    filter: {status: {label: {notEq: "DRAFT"}}}
    orderBy: {createdAt: desc}
  ) {
    id
    date
    score {
      count
      average
    }
    partialScore {
      count
      average
      type
    }
    projectName
    client {
      label
    }
    thumbnail {
      url
      locales {
        alt
      }
    }
    tags {
      label
    }
  }
}
    `;
export const StoryByIdDocument = gql`
    query storyById($id: UUID) {
  story: getStory(by: {id: $id}) {
    id
    date
    description
    score {
      count
      average
    }
    storytellingScore: partialScore(filter: {type: {eq: storytelling}}) {
      count
      average
    }
    craftScore: partialScore(filter: {type: {eq: craft}}) {
      count
      average
    }
    insightScore: partialScore(filter: {type: {eq: insight}}) {
      count
      average
    }
    distinctivenessScore: partialScore(filter: {type: {eq: distinctiveness}}) {
      count
      average
    }
    salienceScore: partialScore(filter: {type: {eq: salience}}) {
      count
      average
    }
    targetingScore: partialScore(filter: {type: {eq: targeting}}) {
      count
      average
    }
    credits {
      name
      role {
        label
      }
    }
    metrics {
      name
      value
    }
    result
    projectName
    client {
      label
    }
    thumbnail {
      url
      locales {
        alt
      }
    }
    tags {
      label
    }
    primaryImage {
      url
      type
      size
    }
    primaryVideo {
      url
      type
      size
    }
    caseStudy {
      url
      type
      size
    }
    caseStudyPreview {
      url
      type
      size
    }
    caseStudyLength
    blocks {
      ...blockFields
    }
  }
}
    ${BlockFieldsFragmentDoc}`;
export const CreateVoteDocument = gql`
    mutation createVote($data: VoteCreateInput!) {
  createVote(data: $data) {
    ok
    errorMessage
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    stories(variables?: StoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StoriesQuery>(StoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stories', 'query');
    },
    storyById(variables?: StoryByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StoryByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StoryByIdQuery>(StoryByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'storyById', 'query');
    },
    createVote(variables: CreateVoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateVoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateVoteMutation>(CreateVoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createVote', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;