
import {defineComponent} from 'vue'
import config from "@/config/config";

export default defineComponent({
    name: 'PicfittedImage',
    components: {},
    props: {
        src: {
            type: String as () => String | undefined,
            required: true
        },
        alt: String,
        class: String,
        operation: String,
        width: String,
        height: String,
        noLightbox: Boolean
    },
    data() {
        return{
            showLightbox: false
        }
    },
    methods: {
        handleLightbox() {
            if (!this.showLightbox){
                this.showLightbox = true;
                document.body.style.overflow = 'hidden'
            } else {
                this.showLightbox = false;
                document.body.style.overflow = 'visible'
            }
        }
    },
    setup(props) {
        return {
            ...props
        }
    },
    computed: {
        getUrl: function () {
            let prefix = '';
            if (config.PICFIT_DISPLAY_URL !== null) {
                prefix = `${config.PICFIT_DISPLAY_URL}?`

                prefix += `op=${this.operation ?? 'resize'}`
                prefix += `&w=${this.width ?? 0}`
                prefix += `&h=${this.height ?? 0}`
                prefix += '&url='
            }

            return `${prefix}${this.src}`
        },
        className: function () {
            return this.class
        },
        ratioClassName: function () {
            const img: HTMLImageElement | undefined = this.$refs.lightboxImage as HTMLImageElement;

            if (!img || !img.complete) {
                return ''
            }

            const w = img.naturalWidth;
            const h = img.naturalHeight;

            if (w > h) {
                return 'landscape';
            } else if ( h > w ) {
                return 'portrait';
            } else {
                return 'square';
            }
        }
    },
    unmounted() {
        document.body.style.overflow = 'visible'
    }
});
