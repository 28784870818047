<template>
    <div
        v-if="flashMessages"
        class="flash-message">
        <div class="flash-message__wrapper"
             v-for="(item, index) in flashMessages"
             :key="index"
             :class="item.type">
            <div class="flash-message__inner">
                <h6
                    class="flash-message__text"
                    v-html="item.text" />
            </div>
        </div>

    </div>
    <div class="container-fuild">
        <div class="hero background--blue" v-if="currentRoute !== 'Story' ">
            <div class="container__inner header">
                <div class="col">
                    <div class="header p-3">
                        <router-link to="/" class="header__logo">
                            McCann Square
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="">
                <router-view v-slot="{ Component }">
                    <transition name="fade" mode="out-in">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </div>
        <div id="ratingwrapper"></div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';
import { useMainStore } from "@/store/mainStore";

export default {
    components: { },
    setup() {

        const route = useRoute();
        const currentRoute = ref(route.name)

        watch(
            () => route.name,
            newRoute => {
                currentRoute.value = newRoute
            }
        )

        return {
            currentRoute
        }

    },
    computed: {
        flashMessages: () => useMainStore().flashMessages
    }
}
</script>


<style lang="scss">
@import "~@/assets/styles/styles";
.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
opacity: 0;
}

.container__inner{
    padding: 44px 156px 135px;

    @media screen and (max-width: 1024px){
        padding: 36px 40px 142px;
    }

    @media screen and (max-width: 540px){
        padding: 36px 16px 142px;
    }

    &.header{
        padding: 44px 156px;

        @media screen and (max-width: 1024px){
            padding: 44px 40px;
        }

        @media screen and (max-width: 540px){
            padding: 16px;
        }
    }

    &.hero{
        padding: 0 156px 120px;

        @media screen and (max-width: 1024px){
            padding: 0 40px 120px;
        }

        @media screen and (max-width: 540px){
            padding: 0 16px 120px;
        }
    }

    &.footer{
        padding: 270px 156px 44px;

        @media screen and (max-width: 1024px){
            padding: 270px 40px 44px;
        }

        @media screen and (max-width: 540px){
            padding: 270px 16px 0;
        }
    }

    &.footer-bottom{
        padding: 44px 156px;
        border-top: 1px solid $color-grey-dark;

        @media screen and (max-width: 1024px){
            padding: 44px 40px;
        }

        @media screen and (max-width: 540px){
            padding: 36px 16px;
        }
    }

    &.story-detail{
        background-color: $color-blue;
        border-top: 1px solid $color-grey-dark;
        padding: 44px 156px 120px;
        width: 100%;

        @media screen and (max-width: 1024px){
            padding: 36px 40px 120px;
        }

        @media screen and (max-width: 540px){
            padding: 20px 16px 80px;
        }
    }

    .footer-bottom__inner{
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 1499px){
            width: 1140px;
            margin: 0 auto;
        }

        .link-wrapper{
            display: flex;
            align-items: center;
            margin-right: 20px;

            @media screen and (max-width: 1200px){
                margin-right: 8px;
            }

            @media screen and (max-width: 540px){
                margin-right: 0;
            }
        }

        .link-white{
            color: $color-white;
            font-size: 18px;
            cursor: pointer;

            @media screen and (max-width: 540px){
                padding-right: 16px;
            }

            &:hover{
                text-decoration: none;
            }
        }

        &.padding-bottom{
            padding-bottom: 20px;

            @media screen and (min-width: 541px){
                padding-bottom: 220px;
            }

            @media screen and (min-width: 994px){
                padding-bottom: 140px;
            }

            @media screen and (min-width: 1233px){
                padding-bottom: 80px;
            }
        }
    }

    &.footer--story{
        padding: 320px 156px 44px;

        @media screen and (max-width: 1024px){
            padding: 320px 40px 44px;
        }

        @media screen and (max-width: 540px){
            padding: 236px 16px 36px;
        }
    }

    &.stories-wrapper{
        padding-bottom: 56px;
    }
}
</style>
