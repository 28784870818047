import {defineStore} from 'pinia';
import {FlashMessage} from '~/types/flashMessage';

const cookieName = 'votedIDs';

export const useMainStore = defineStore(
    'main',
    {
        state: () => ({flashMessages: [] as FlashMessage[]}),
        actions: {
            addFlashMessage(message: FlashMessage) {
                const flash: FlashMessage = {
                    ...message,
                    id: (Math.random() + 1).toString(36).substring(7)
                }

                this.flashMessages.push(flash)
                setTimeout((id) => {
                    this.flashMessages = this.flashMessages.filter((msg) => msg.id !== id)
                }, 5000, flash.id);
            },
            clearFlashMessages() {
                this.flashMessages = []
            },
            markStoryVoted(storyId: string): void {
                const ids = new Set(getCookiedIds())
                ids.add(storyId)
                setCookie([...ids])
            },
            canVoteForStory(storyId: string): boolean {
                return !getCookiedIds().includes(storyId)
            }
        },
    }
)

const getCookiedIds = (): string[] => {
    if (!document.cookie.includes(cookieName + '=')) {
        return [];
    }

    const allCookies = document.cookie.split('; ')
    const ourCookie = allCookies.find((cookie: string) => cookie.includes(cookieName + '='))
    if (ourCookie === null) {
        return []
    }

    const cookieSplit = ourCookie?.split('=') ?? []
    if (cookieSplit.length !== 2){
        return []
    }

    return JSON.parse(cookieSplit[1])
}

const setCookie = (ids: string[]): void => {
    const idsString = JSON.stringify(ids)

    document.cookie = `${cookieName}=${idsString}; Secure`;
}
