import { GraphQLClient } from "graphql-request";
import config from '@/config/config'

const client = new GraphQLClient(
    config.GRAPHQL_HOST,
    {
        "headers": {
            "authorization": `Bearer ${config.GRAPHQL_TOKEN}`
        }
    }
)

export default client
